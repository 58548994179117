import { call, put } from 'redux-saga/effects';

import { response, responseCallback } from '@api/helpers';
import portfolioAPI from '@api/endpoints/portfolio';
import { Portfolio, PortfolioCompany } from '@models/portfolio';
import * as actions from '@store/portfolio/actions';
import { UniverseToAddResponse } from '@api/schemas/portfolio';
import brokerApi from '@api/endpoints/broker';
import { BySymbolPortfolioResponse } from '@api/schemas/broker';
import { onInfoNotification } from '@domain/events/info';
import { BROKER_LOADING_ERROR } from '@domain/constants/error-messages';

export const getIssueTypeDisplay = (item: PortfolioCompany): string => {
  if (isPortfolioItemETF(item)) {
    return 'ETF';
  }
  if (isPortfolioItemBond(item)) {
    return 'Bond ETF';
  }
  return '';
};

export const isPortfolioItemCompany = (item: PortfolioCompany): boolean => (
  !isPortfolioItemETF(item) && !isPortfolioItemBond(item)
);

export const isPortfolioItemETF = (item: PortfolioCompany): boolean => (
  item?.issue_type === 'et'
);

export const isPortfolioItemBond = (item: PortfolioCompany): boolean => (
  item?.issue_type === 'bnd'
);

export function* loadPortfolio () {
  yield put(actions.fetchPortfolioStarted());
  try {
    const portfolio: Portfolio = yield call(response, portfolioAPI.retrieve());
    yield put(actions.fetchPortfolioSucceeded(portfolio));
  }
  catch (e) {
    yield put(actions.fetchPortfolioFailed(e));
    throw e;
  }

  try {
    const portfolioInfo: BySymbolPortfolioResponse[] = yield call(response, brokerApi.fetchPortfolio(),);
    yield put(actions.fetchPortfolioInfoSucceeded(portfolioInfo));
  }
  catch (e) {
    onInfoNotification(BROKER_LOADING_ERROR);
    console.error('failed to load portfolio info', e);
  }
}

export const getAvailablePortfolioToAdd = async (page = 1, search = ''): Promise<UniverseToAddResponse> => (
  response<UniverseToAddResponse>(
    portfolioAPI.fetchPortfolioAvailableUniverse(page, search ? `query=${search}` : ''),
  )
);
