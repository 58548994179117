import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import { response } from '@api/helpers';
import * as builder from '@store/builder';

import { userSettingsSelector } from './userSettings.selectors';
import { UserSettingsRD, UserSettings } from './settings.store.types';
import { userApi } from '@api/endpoints/user';

const action = builder.getModuleAction('USER_SETTINGS');

const FETCH = action('FETCH');
const SET = action('SET');
const MODIFY = action('MODIFY');

const initialState: UserSettingsRD = RD.initial;

export const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

const setUserInfo = (payload: UserSettingsRD) => ({
  type: SET,
  payload: payload
});

function* loadUserSettings () {
  yield put(setUserInfo(RD.pending));

  try {
    const userSettings: UserSettings = yield call(response, userApi.getUserMetaInfo());

    const result: UserSettings = {
      experiments: userSettings?.experiments || [],
    };

    yield put(setUserInfo(userSettings ? RD.success(result) : RD.success({})));
  }
  catch (e) {
    yield put(setUserInfo(RD.failure(e)));
  }
}
export function* watchLoadUserSettings () {
  yield takeLatest(FETCH, loadUserSettings);
}
export const loadUserSettingAction = builder.buildRequestAction(FETCH);

function* modifyUserSettings (action) {
  try {
    const state = yield select();
    const settingsState = userSettingsSelector(state);

    const requestData = { ...action.payload };

    const apiResponse: UserSettings = yield call(response, userApi.setUserMetaInfo(requestData));

    const result = pipe(
      settingsState,
      RD.map(data => ({ ...data, ...apiResponse })),
      RD.alt(() => RD.success({ experiments: [] } as UserSettings))
    );
    yield put(setUserInfo(result));
  }
  catch (_) {
    const state = yield select();
    const settingsState = userSettingsSelector(state);
    const result = pipe(
      settingsState,
      RD.map(data => ({ ...data })),
      RD.alt(() => RD.success({ experiments: [] } as UserSettings))
    );
    yield put(setUserInfo(result));
  }
}

export function* watchModifyUserSettings () {
  yield takeLatest(MODIFY, modifyUserSettings);
}

export const modifyUserSettingAction = (payload: UserSettings) => ({
  type: MODIFY,
  payload
});
