import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';

import { achSelector } from '~/entities/ach';
import { ACHRelationship } from '@models/transfers';

import { ListProps } from '../../ACH.feature.types';
import { PlainAccount } from '../PlainAccount';
import { isExternalAccountsFactory, useAccountsValue } from '../helpers';
import * as Styled from './AccountSelect.styled';

type Props = ListProps & { selectedAccount: ACHRelationship };

export const AccountSelect = React.forwardRef<any, Props>((props, ref) => {
  const activeACHAccount = useSelector(achSelector);
  const [activeId, setActiveId] = useState(props.selectedAccount.account_number);

  useEffect(() => {
    setActiveId(props.selectedAccount.account_number);
  }, [props.selectedAccount.account_number]);

  const achAccounts = useAccountsValue();
  const isExternalAccount = useCallback(isExternalAccountsFactory(achAccounts), [achAccounts]);

  const onSelect = useCallback((targetId: string) => {
    if (props.onSelect && targetId) {
      props.onSelect(targetId);
    }

    setActiveId(targetId);
  }, [props.onSelect]);

  const currentAccountOption = useMemo(() => {
    return {
      value: props.selectedAccount.account_number,
      label: (
        <PlainAccount
          {...props.selectedAccount}
          isExternal={isExternalAccount(props.selectedAccount)}
          isRemovable={false}
        />
      )
    };
  }, [
    props.selectedAccount,
    isExternalAccount,
  ]);

  const activeAccountOption = useMemo(() => {
    const val = RD.toUndefined(activeACHAccount);
    if (!val) {
      return;
    }

    return {
      value: val.account_number,
      label: (
        <PlainAccount {...val} isExternal={isExternalAccount(val)} isRemovable={false} />
      )
    };
  }, [
    activeACHAccount,
    isExternalAccount,
  ]);

  const value = useMemo(() => {
    const activeItem = props.accounts.find(({ account_number: accountNumber }) => activeId === accountNumber);
    if (!activeItem) {
      return currentAccountOption;
    }

    return {
      value: activeItem.account_number,
      label: (
        <PlainAccount {...activeItem} isExternal={isExternalAccount(activeItem)} isRemovable={false} />
      )
    };
  }, [props.accounts, activeId, currentAccountOption, isExternalAccount]);

  const mixWithBeforeElement = useCallback((options: ({value: string, label: React.ReactNode})[] = []) => {
    if (props.before) {
      return [{ value: 'external', label: <>{props.before}</> }, ...options];
    }
    return options;
  }, [props.before]);

  const options = useMemo(() => {
    const options = (
      props.accounts.map(
        (account) => ({
          value: account.account_number,
          label: (
            <PlainAccount {...account} isExternal={isExternalAccount(account)} isRemovable={false} />
          )
        })
      )
    );

    if (options.findIndex(option => option.value === activeAccountOption.value) > -1) {
      return mixWithBeforeElement(options);
    }

    return mixWithBeforeElement([activeAccountOption, ...options]);
  }, [props.accounts, currentAccountOption, value, activeAccountOption, isExternalAccount, mixWithBeforeElement]);

  return (
    <Styled.Container
      ref={ref}
      value={value?.value || ''}
      options={options}
      onSelect={onSelect}
    />
  );
});
