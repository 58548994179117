import { AxiosPromise } from 'axios';

import api from '~/api/base';
import { RegisterRequestBody } from '@api/schemas/auth';

import { AuthData, TokensResponse } from '@models/auth';

const authAPI = {
  getTokens: (authData: AuthData): AxiosPromise<TokensResponse> =>
    api.post('/serve/token/', authData),

  register: (data: RegisterRequestBody): AxiosPromise<unknown> => api.post(
    '/register/',
    data,
  ),
};

export default authAPI;
