import { AxiosPromise } from 'axios';

import { privateAPI } from '~/api/base';

import { Impact } from '@models/impact';

const projectionApi = {
  impact: {
    fetch: (): AxiosPromise<Impact> => privateAPI.get('/projection/impact/'),
  },

  fetch: (): AxiosPromise<unknown> => privateAPI.get(
    '/projection/financial/',
  ),

  drop: (): AxiosPromise<unknown> => privateAPI.get(
    '/projection/drop/',
  ),
};

export default projectionApi;
