import { entries } from 'lodash';
import every from 'lodash/every';
import { call, put } from 'redux-saga/effects';

import brokerApi from '@api/endpoints/broker';
import { responseCallback } from '@api/helpers';

import * as actions from '@store/broker_account/actions';

import { BrokerAccount } from '@models/broker';
import { types } from '@pages/onboarding_alpaca/KYC';
import { onInfoNotification } from '@domain/events/info';
import { BROKER_LOADING_ERROR } from '@domain/constants/error-messages';

export function* loadBrokerAccount () {
  try {
    yield put(actions.fetchBrokerAccountStarted());
    const account: BrokerAccount = yield call(
      responseCallback<BrokerAccount>(brokerApi.fetchAccountTradingInfo),
    );
    yield put(actions.fetchBrokerAccountSucceeded(account));
  }
  catch (e) {
    onInfoNotification(BROKER_LOADING_ERROR);
    yield put(actions.fetchBrokerAccountFailed(e));
  }
}

export const getRequiredKYCTypes = (results) =>
  !!results && 'indeterminate' in results
    ? (
      entries(results.indeterminate || {})
        .filter(([key, _]) => Object.keys(types).includes(key))
        .filter(([_, v]) => v?.is_uploaded !== true)
    )
    : [];

const actionTypes = {
  indoors: [
    types.TAX_IDENTIFICATION,
    types.IDENTITY_VERIFICATION,
    types.INVALID_IDENTITY_PASSPORT,
    types.VISA_TYPE_OTHER,
    types.ADDRESS_VERIFICATION,
    types.DATE_OF_BIRTH,
  ],
  byEmail: [
    types.PEP,
    types.FAMILY_MEMBER_PEP,
    types.AFFILIATED,
    types.CONTROL_PERSON,
  ]
};

export const getIndoorRequiredKYCTypes = (results) => {
  return !!results && 'indeterminate' in results
    ? (
      entries(results.indeterminate || {})
        .filter(([key, _]) => actionTypes.indoors.includes(key))
        .filter(([_, v]) => v?.is_uploaded !== true)
    )
    : [];
};

export const getIndoorPendingKYCStatus = (results) => {
  return !!results && 'indeterminate' in results
    ? every(
      entries(results.indeterminate || {})
        .filter(([key, _]) => actionTypes.indoors.includes(key)),
      ([_, v]) => v?.is_uploaded === true,
    )
    : false;
};

export const getEmailRequiredKYCTypes = (results) => {
  return !!results && 'indeterminate' in results
    ? (
      entries(results.indeterminate || {})
        .filter(([key, _]) => actionTypes.byEmail.includes(key))
        .filter(([_, v]) => v?.is_uploaded !== true)
    )
    : [];
};
