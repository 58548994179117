import { createSelector } from 'reselect';

import { Organization } from '@models/organization';
import { OrganizationState } from '@store/organization';

export const organizationSelector = (state: any): OrganizationState => state.Organization;

export const selectOrganization = createSelector(
  organizationSelector,
  (Organization) => Organization.organizationInfo,
);

export const selectIsOrganizationLoading = createSelector(
  organizationSelector,
  (Organization: OrganizationState) => Organization.isLoading,
);

export const selectOrganizationError = createSelector(
  organizationSelector,
  (Organization: OrganizationState) => Organization.error,
);

export const selectIsOrganizationInitialLoading = createSelector(
  selectOrganization,
  selectIsOrganizationLoading,
  (organization: Organization | null, loading: boolean) => organization === null && loading,
);
