import { ACHRelationship } from '@models/transfers';

import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import { useSelector } from 'react-redux';
import { achAccountsSelector } from '~/entities/ach';
import { useMemo } from 'react';

const EMPTY_ACCOUNTS: ACHRelationship[] = [];
export const useAccountsValue = () => {
  const accountsRD = useSelector(achAccountsSelector);

  return useMemo(() => F.pipe(
    accountsRD,
    RD.getOrElse(() => EMPTY_ACCOUNTS)
  ), [accountsRD]);
};

export const isExternalAccountsFactory = (achAccounts: ACHRelationship[]) => {
  return ({ account_number: targetId }: ACHRelationship) =>
    achAccounts
      .findIndex(({ account_number }) => account_number === targetId) < 0;
};
