import { AxiosPromise } from 'axios';

import api from '~/api/base';

import { TopicCategories } from '@models/topic-categories';

const topicCategoriesApi = {
  fetch: (): AxiosPromise<TopicCategories> => api.get('/platform/topic-categories/'),
};

export default topicCategoriesApi;
