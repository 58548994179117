import { AxiosPromise } from 'axios';
import { privateAPI } from '~/api/base';

const companyApi = {
  fetch: (companyName: string, params = {}): AxiosPromise<unknown> => privateAPI.get(
    `/company/${companyName}/`,
    { params }
  ),
  delete: (companyName: string): AxiosPromise<unknown> => privateAPI.delete(
    `/remove_company_from_portfolio/${companyName}/`,
  ),
};

export default companyApi;
