import { ConfigProvider } from 'antd';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppMediaContextProvider } from '~/contexts/AppMedia';

import App from './App.jsx';
import store from '@store';

import { theme } from './ui/theme';

export default function AppProvider () {
  return (
    <Provider store={store}>
      <ConfigProvider theme={theme}>
        <AppMediaContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppMediaContextProvider>
      </ConfigProvider>
    </Provider>
  );
};
