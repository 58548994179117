import { authAPI } from '~/api';
import { privateAPI } from '~/api/base';
import { getRefreshToken, INVALID_TOKEN_CODE, saveAccessToken } from '~/authentication/auth';
import { getCredentials } from '~/domain/services/auth';

export const keepDoing = data => data;

export const addHeadersInterceptor = async config => {
  const { token } = getCredentials();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

export const refreshTokenInterceptor = async error => {
  const { data } = error.response || {};
  const config = error?.config;

  if (data?.code === INVALID_TOKEN_CODE && config) {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      const res = await authAPI.refreshToken(refreshToken);
      const { access } = res.data;

      if (access) {
        saveAccessToken(access);
        return privateAPI.request(config);
      }
    }
  }

  return error;
};
