import Types from './types';
import { MenuType } from '@models/application';

interface Payload {
  isLoading: boolean,
  error: string,
}

export interface LoadAppPayload {
  tokens?: {
    accessToken: string;
    refreshToken: string;
  },
  successCallback: () => void;
}

export type LoadAppAction = {
  type: Types.LOAD,
  payload: LoadAppPayload | null,
};

export type SetMenuAction = {
  type: Types.SET_MENU,
  payload: MenuType,
}

export type Action = {
  type: Types,
  payload: Payload,
} | LoadAppAction | SetMenuAction;

export const loadApplication = (payload: LoadAppPayload | null = null): LoadAppAction => ({
  type: Types.LOAD,
  payload,
});

export const setIsExternal = (isExternal: boolean) => ({
  type: Types.SET_IS_EXTERNAL,
  payload: { isExternal },
});

export const setMenuType = (type: MenuType) => ({
  type: Types.SET_MENU,
  payload: type,
});

export const loadingAppStarted = (): Action => ({
  type: Types.LOADING_STARTED,
  payload: {
    isLoading: true,
    error: '',
  },
});

export const loadingAppSucceeded = (): Action => ({
  type: Types.LOADING_SUCCEEDED,
  payload: {
    isLoading: false,
    error: '',
  },
});

export const loadingAppFailed = (error: string): Action => ({
  type: Types.LOADING_FAILED,
  payload: {
    isLoading: false,
    error,
  },
});

export const reset = () => ({
  type: Types.RESET,
});

export * from './notifications/actions';
