import React from 'react';
import { FormInstance } from 'antd';
import { useDispatch } from 'react-redux';
import * as O from 'fp-ts/Option';
import { isEmpty } from 'lodash';

import { EditACHValue } from '~/entities/ach';
import { validateACH } from '@services/transfers';

import { setEditACH } from '../api/stores';

export const usePrepareAchFromForm = (formRef: React.RefObject<FormInstance>) => {
  const dispatch = useDispatch();

  return (onSuccess: (editAchValue?: EditACHValue) => void, onError: (error: string) => void) => {
    const form = formRef.current;

    if (form) {
      const { name } = (form?.getFieldsValue() || {});
      if (!name) {
        onSuccess();
        return;
      }

      const newAch = {
        account_owner_name: form.getFieldValue('name'),
        account_type: form.getFieldValue('accountType'),
        account_number: form.getFieldValue('accountNumber'),
        routing_number: form.getFieldValue('accountRouting'),
      };

      const formValidation = (validateACH(newAch));

      if (isEmpty(formValidation)) {
        const editAch = O.some(newAch);
        dispatch(setEditACH(editAch));
        onSuccess(editAch);
      }
      else {
        onError(`Please fill account info correctly`);
      }
    }
  };
};
