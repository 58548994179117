import { createSelector } from 'reselect';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { AlpacaAccountState } from './alpaca_account.types';

export const alpacaAccountSelector = (state: any): AlpacaAccountState => state.AlpacaAccount;

export const isAccountVerifiedSelector = createSelector(
  alpacaAccountSelector,
  state => pipe(
    state,
    RD.map(({ status }) => status === 'ACTIVE'),
    RD.getOrElse(() => false)
  ),
);
