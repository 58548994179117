import brokerApi from '@api/endpoints/broker';

import formValidator from '@components/achrelationship/validators';

import { Profile } from '@models/profile';
import { ACHRelationship } from '@models/transfers';

export const getACHNickname = (ach?: ACHRelationship) => ach?.nickname || 'Connected account';

export const isSameAccounts = (one: ACHRelationship, another: ACHRelationship) => (
  one.account_number === another.account_number
);

export const validateACH = (ach: ACHRelationship) => formValidator(ach);

export const getIsPreFillAmountRequired = (profile: Profile) => !profile.is_first_deposit_created;

export const getFirstDepositAmount = (profile: Profile) => profile.investment_amount;

export const deposit = async (amount: number) => brokerApi.transfer({
  amount,
  transfer_type: 'ach',
  direction: 'INCOMING',
});

type Period = { weeks: number} | { months: number }

export const createRecurringDeposit = async (period: Period, amount: number) => brokerApi.createRecurringTransfer({
  amount,
  period
});

export const resumeRecurringDeposit = async (id: number, period: Period, amount: number) => brokerApi.resumeRecurringTransfer(id, {
  amount,
  period
});

export const editRecurringDeposit = async (id: number, period: Period, amount: number) => brokerApi.editRecurringTransfer(id, {
  amount,
  period
});

export const removeRecurringDeposit = async (id: number) => brokerApi.removeRecurringDeposit({
  scheduleId: id
});

export const getRecurringDeposits = async () => brokerApi.getRecurringTransfers();

export const withdraw = async (amount: number) => brokerApi.transfer({
  amount,
  transfer_type: 'ach',
  direction: 'OUTGOING',
});

export const withdrawAll = async () => brokerApi.withdrawAll({
  transfer_type: 'ach',
});
