export interface RequestAction {
  type: string;
}

export interface ResetAction {
  type: string;
  payload: {
    [key: string]: any;
    isLoading: boolean;
    error: string;
  };
}

export interface StartAction {
  type: string;
  payload: {
    isLoading: boolean;
    error: string;
  };
}

export interface SuccessAction {
  type: string;
  payload: {
    [key: string]: any
    isLoading: boolean;
    error: string;
  };
}

export interface FailedAction {
  type: string;
  payload: {
    isLoading: boolean;
    error: string;
  };
}

export const getModuleAction = (moduleName: string) => (actionName: string) => `${moduleName}_${actionName}`;

export const buildRequestAction = <O extends RequestAction = RequestAction> (actionName: string) => (
  (extra = {}): O => ({ type: actionName, ...extra }) as O
);

export const buildSimpleResetAction = <O extends ResetAction = ResetAction> (actionName: string) => (
  (): O => ({
    type: actionName,
    payload: {
      isLoading: false,
      error: '',
    },
  }) as O
);

export const buildStartedAction = <O extends StartAction = StartAction> (actionName: string) => (
  (): O => ({
    type: actionName,
    payload: {
      isLoading: true,
      error: '',
    },
  }) as O
);

export const buildSimpleSucceededAction = <O extends SuccessAction = SuccessAction> (actionName: string) => (
  (): O => ({
    type: actionName,
    payload: {
      isLoading: false,
      error: '',
    },
  }) as O
);

export const buildSucceededAction = <I, O extends SuccessAction = SuccessAction> (actionName: string, fieldName: string) => (
  (data: I): O => ({
    type: actionName,
    payload: {
      [fieldName]: data,
      isLoading: false,
      error: '',
    },
  }) as O
);

export const buildFailedAction = <O extends FailedAction = FailedAction> (actionName: string) => (
  (error: string): O => ({
    type: actionName,
    payload: {
      isLoading: false,
      error,
    },
  }) as O
);
