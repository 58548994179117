import { createSelector } from 'reselect';

import { Notifications } from '@models/application/notifications';

import { AppState } from '@store/application';

const selectApp = (state: any): AppState => state.Application;

export const selectNotifications = createSelector(
  selectApp,
  (App): Notifications => App.notifications,
);
