import * as builder from '@store/builder';
import TopicCategoriesTypes from '@store/topic_categories/types';

import { TopicCategories } from '@models/topic-categories';

export const fetchTopicCategories = builder.buildRequestAction(TopicCategoriesTypes.FETCH_TOPIC_CATEGORIES);
export const fetchTopicCategoriesStarted = builder.buildStartedAction(TopicCategoriesTypes.FETCH_TOPIC_CATEGORIES_STARTED);
export const fetchTopicCategoriesSucceeded = builder.buildSucceededAction<TopicCategories>(
  TopicCategoriesTypes.FETCH_TOPIC_CATEGORIES_SUCCEEDED,
  'categories',
);
export const fetchTopicCategoriesFailed = builder.buildFailedAction(TopicCategoriesTypes.FETCH_TOPIC_CATEGORIES_FAILED);
