import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as RD from '@devexperts/remote-data-ts';

import { ACHRelationship } from '@models/transfers';
import { altOnError, sequenceT } from '@utils/RemoteData';
import { ManualACHForm } from '~/features/ACH';
import { ConnectError, ConnectErrorType } from '~/features/ACH/ConnectExternal/types';
import { ACHStateType, ACHWidget } from '~/widgets/ACH';

import { achSelector, achAccountsSelector } from '../api/selectors';
import { setEditACH, loadACHAction, loadACHAccountsAction } from '../api/stores';
import { useActiveACHRelationship } from '../hooks';
import { CommonProps, ConnectComponentProps } from '../types';
import { FallbackDescription } from './FallbackDescription';
import * as Styled from './Common.styled';

export const IntegratedACHInput: React.FC<
CommonProps & {
  ConnectComponent: React.ComponentType<ConnectComponentProps>;
}> = (props) => {
  const dispatch = useDispatch();

  const achRD = useSelector(achSelector);
  const [showManualFallback, setShowManualFallback] = useState(false);

  useEffect(() => {
    if (props.onManualChanged) {
      props.onManualChanged(showManualFallback);
    }
  }, [showManualFallback, props.onManualChanged]);

  const accountsPlain = useSelector(achAccountsSelector);

  const accounts = useMemo(() => F.pipe(
    accountsPlain,
    altOnError(() => RD.success([]))
  ), [accountsPlain]);

  const isAccountsEmpty = F.pipe(accounts, RD.map(A.isEmpty), RD.getOrElse(() => true));
  const isAchSelected = useMemo(() => RD.isSuccess(achRD), [achRD]);

  const onError = useCallback((e: ConnectError) => {
    props.onExternalError(e);
    if (e.type === ConnectErrorType.BANK_NOT_FOUND || isAccountsEmpty) {
      setShowManualFallback(true);
    }
  }, [isAccountsEmpty]);

  const Connect = useMemo(() =>
    !props.disableExternalAccounts && (
      <props.ConnectComponent
        showConfirmation={RD.isSuccess(achRD)}
        onError={onError}
        viewType={
          isAchSelected
            ? 'select'
            : 'list'
        }
      />
    ), [onError, props.ConnectComponent, achRD, props.disableExternalAccounts, isAccountsEmpty]);

  const onSelect = useCallback((accountId: string) => {
    F.pipe(
      accounts,
      RD.map(accounts =>
        F.pipe(
          accounts,
          A.findFirst(account => account.account_number === accountId),
          O.chain(O.fromPredicate(ach => ach.account_number === accountId)),
          s => dispatch(setEditACH(s)),

        ),
      ),
    );
  }, [accounts, achRD]);

  const allowedAch = useMemo(() => F.pipe(
    sequenceT(achRD, accounts),
    RD.chain(([currentAch, integratedAccounts]) => {
      if (props.lockReason || !props.disableExternalAccounts || integratedAccounts.length === 0) {
        return RD.success(currentAch);
      }

      if (currentAch.account_number === integratedAccounts[0].account_number) {
        return RD.success(currentAch);
      }

      return RD.failure(Error('Only integrated accounts allowed'));
    })
  ), [achRD, accounts, props.disableExternalAccounts, props.lockReason]);

  const activeAch = useActiveACHRelationship();
  const activeACHSelect = useMemo(() => F.pipe(activeAch, O.toUndefined), [activeAch]);

  useEffect(() => F.pipe(
    accounts,
    RD.fold(
      () => {},
      () => {},
      () => {
        if (!Connect && !activeACHSelect && RD.isFailure(achRD)) {
          onError({
            type: ConnectErrorType.UNKNOWN
          });
        }
      },
      (accounts) => {
        if (!accounts.length && !Connect && RD.isFailure(achRD)) {
          onError({
            type: ConnectErrorType.UNKNOWN
          });
        }
      },
    )
  ), [accounts, Connect, onError, showManualFallback, achRD]);

  return useMemo(() => {
    if (showManualFallback) {
      return (
        <>
          <FallbackDescription onClick={() => {
            setShowManualFallback(false);
            dispatch(loadACHAction());
            dispatch(loadACHAccountsAction());
            props.onExternalExit();
          }}/>
          {ManualACHForm}
        </>
      );
    }

    return F.pipe(
      accounts,
      RD.chain(
        accounts => F.pipe(
          allowedAch,
          RD.map(
            ach =>
              !props.lockReason
                ? (
                  <ACHWidget
                    type={ACHStateType.SELECT}
                    options={accounts}
                    // We can cast it 'cuz at least achRD was successfully loaded
                    achRelationship={activeACHSelect as ACHRelationship}
                    onACHSelect={onSelect}
                    Before={Connect}
                  />
                )
                : (
                  <ACHWidget reason={props.lockReason} type={ACHStateType.LOCKED} achRelationship={activeACHSelect} />
                ),
          ),
          // In case there is no ACH relationship detected
          altOnError(
            () =>
              F.pipe(
                accounts,
                (accounts) => (
                  <ACHWidget
                    achRelationship={activeACHSelect}
                    type={ACHStateType.LIST}
                    options={accounts}
                    onACHSelect={onSelect}
                    Before={Connect}
                  />
                ),
                RD.success
              )
          ),
        )
      ),
      RD.fold(
        () => <></>,
        () => <Styled.Loader />,
        () => Connect,
        F.identity
      )
    );
  }, [onSelect, Connect, accounts, allowedAch, activeACHSelect, props.lockReason, showManualFallback]);
};
