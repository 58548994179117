import { useCallback } from 'react';
import * as F from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { useDispatch, useSelector } from 'react-redux';

import { setACHAction, setEditACH } from '../api/stores';
import { editACHSelector } from '../api/selectors';

export const useHandleConfirm
    = <T extends unknown>(
      onSuccess: (successData?: T) => void,
      onError: (e: string) => void,
      onFinish?: VoidFunction
    ) => {
      const editACH = useSelector(editACHSelector);
      const dispatch = useDispatch();

      return useCallback((successData?: T, editACHValue = editACH) => {
        return F.pipe(
          editACHValue,
          O.fold(
            () => {
              onSuccess(successData);
            },
            (newAch) => {
              dispatch(
                setACHAction(
                  newAch,
                  () => {
                    dispatch(setEditACH(O.none));
                    onSuccess(successData);
                  },
                  onError,
                )
              );
            }
          ),
          () => {
            onFinish && onFinish();
          }
        );
      }, [onFinish, onError, onSuccess, editACH]);
    };
