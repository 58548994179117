import { call, put } from 'redux-saga/effects';

import * as actions from '@store/impact/actions';

import impactApi from '@api/endpoints/impact';
import { responseCallback } from '@api/helpers';

import { Impact } from '@models/impact';

export function* loadImpact () {
  try {
    yield put(actions.fetchImpactStarted());
    const impact: Impact = yield call(
      responseCallback<Impact>(impactApi.fetch),
    );
    yield put(actions.fetchImpactSucceeded(impact));
  }
  catch (e) {
    yield put(actions.fetchImpactFailed(e));
    throw e;
  }
}
