import { BrokerageAccountDTO, BrokerageAccount } from '../brokerageAccount.entity.types';

const mapContact = (contact: BrokerageAccount['contact']): BrokerageAccountDTO['contact'] => ({
  email_address: contact.email,
  phone_number: contact.phone,
  city: contact.city,
  postal_code: contact.postalCode,
  state: contact.state,
  street_address: contact.address && [contact.address],
});

const mapIdentity = (identity: BrokerageAccount['identity']): BrokerageAccountDTO['identity'] => {
  return ({
    funding_source: identity.fundingSource ? [identity.fundingSource] : [],
    liquid_net_worth_max: identity.maxIncome,
    liquid_net_worth_min: identity.minIncome,
  });
};

const mapTrustedContact = (trustedContact: BrokerageAccount['trustedContact']): BrokerageAccountDTO['trusted_contact'] => ({
  email_address: trustedContact.email,
  phone_number: trustedContact.phone,
  family_name: trustedContact.familyName,
  given_name: trustedContact.givenName,
});

const mapDisclosures = (disclosures: BrokerageAccount['disclosures']): BrokerageAccountDTO['disclosures'] => ({
  is_affiliated_exchange_or_finra:
      typeof disclosures.isAffiliatedExchangeOrFinra === 'boolean'
        ? disclosures.isAffiliatedExchangeOrFinra
        : disclosures.isAffiliatedExchangeOrFinra === 'true',
  is_control_person:
      typeof disclosures.isControlPerson === 'boolean'
        ? disclosures.isControlPerson
        : disclosures.isControlPerson === 'true',
  immediate_family_exposed:
      typeof disclosures.immediateFamilyExposed === 'boolean'
        ? disclosures.immediateFamilyExposed
        : disclosures.immediateFamilyExposed === 'true',
  employment_status: disclosures.employmentStatus,
  employer_address: disclosures.employerAddress,
  employer_name: disclosures.employerName,
  employment_position: disclosures.employmentPosition,
  is_politically_exposed:
      typeof disclosures.isPoliticallyExposed === 'boolean'
        ? disclosures.isPoliticallyExposed
        : disclosures.isPoliticallyExposed === 'true',
});

export const mapDomainToDtoHelper = (data: Partial<BrokerageAccount>): Partial<BrokerageAccountDTO> => {
  const result: Partial<BrokerageAccountDTO> = {};

  if (data.contact) {
    result.contact = mapContact(data.contact);
  }

  if (data.identity) {
    result.identity = mapIdentity(data.identity);
  }

  if (data.trustedContact) {
    result.trusted_contact = mapTrustedContact(data.trustedContact);
  }

  if (data.disclosures) {
    result.disclosures = mapDisclosures(data.disclosures);
  }

  return result;
};
