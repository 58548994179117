import { Impact } from '@models/impact';

import { ImpactProjectionFetchTypes } from '@store/projection/types';

import * as builder from '../builder';

export const fetchImpactProjection = builder.buildRequestAction(ImpactProjectionFetchTypes.REQUEST);
export const fetchImpactProjectionStarted = builder.buildStartedAction(ImpactProjectionFetchTypes.STARTED);
export const fetchImpactProjectionSucceeded = builder.buildSucceededAction<Impact>(
  ImpactProjectionFetchTypes.SUCCEEDED,
  'projectionInfo',
);
export const fetchImpactProjectionFailed = builder.buildFailedAction(ImpactProjectionFetchTypes.FAILED);
