import { Portfolio } from '@models/portfolio';
import { Profile } from '@models/profile';

import { PlainHighlightedCompanyInfo } from '../highlighted.company.types';

export const getHighlightedCompanyFromPortfolio = (portfolio: Portfolio, profile: Profile): PlainHighlightedCompanyInfo => {
  if (!profile.highlighted_company || !portfolio) {
    return;
  }

  const highlightedIndex = portfolio.findIndex(p => p.company.ticker === profile.highlighted_company);

  if (portfolio[highlightedIndex]) {
    return portfolio[highlightedIndex].company;
  }
};
