import * as RD from '@devexperts/remote-data-ts';

import { ACHRelationship } from '@models/transfers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { response } from '@api/helpers';
import * as builder from '@store/builder';
import { brokerAPI } from '~/api';

import { ACHAccountsValue } from '../types';

const initialState: ACHAccountsValue = RD.initial;

const action = builder.getModuleAction('ACH_ACCOUNTS');

const SET = action('SET');

const FETCH = action('FETCH');

export const ACHAccounts = (state = initialState, action) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

const mock: ACHRelationship[] = [
  {
    account_owner_name: 'алексей Иванович Чепурко',
    account_number: '964516323718333',
    account_type: 'SAVINGS',
    routing_number: '123456789',
    created_at: '2023-04-10T16:16:57.307604Z',
    nickname: null,
    balance: 123
  },
  {
    account_owner_name: 'алексей Иванович Чепурко',
    account_number: '12345678',
    account_type: 'CHECKING',
    routing_number: '123456789',
    created_at: '2023-04-10T16:16:57.307604Z',
    nickname: null,
  },
  {
    account_owner_name: 'алексей Иванович Чепурко',
    account_number: '87654321',
    account_type: 'SAVINGS',
    routing_number: '123456789',
    created_at: '2023-04-10T16:16:57.307604Z',
    nickname: null,
  },
  {
    account_owner_name: 'алексей Иванович Чепурко',
    account_number: '12345678123',
    account_type: 'SAVINGS',
    routing_number: '123456789',
    created_at: '2023-04-10T16:16:57.307604Z',
    nickname: null,
  },
  {
    account_owner_name: 'алексей Иванович Чепурко',
    account_number: '588230',
    account_type: 'SAVINGS',
    routing_number: '123456789',
    created_at: '2023-04-10T16:16:57.307604Z',
    nickname: null,
  },
];

export function* loadACHAccounts () {
  yield put(setACHAccounts(RD.pending));
  try {
    const accounts: ACHRelationship[] = yield call(response, brokerAPI.fetchAccounts());

    // yield put(setACHAccounts(RD.success(mock)));
    yield put(setACHAccounts(accounts ? RD.success(accounts) : RD.success([])));
  }
  catch (e) {
    yield put(setACHAccounts(RD.failure(e)));
    // yield put(setACHAccounts(RD.success(mock)));
  }
}

export function* watchACHAccountsSaga () {
  yield takeLatest(FETCH, loadACHAccounts);
}

export const loadACHAccountsAction = builder.buildRequestAction(FETCH);

const setACHAccounts = (payload: ACHAccountsValue) => ({
  type: SET,
  payload: payload
});
