import { createSelector } from 'reselect';
import { AppState } from '@store/application/index';

const selectApp = (state: any): AppState => state.Application;
const selectCustomization = createSelector(selectApp, App => App.customization);

export const selectIsApplicationLoading = createSelector(
  selectApp,
  App => App.isLoading,
);

export const selectIsExternalUser = createSelector(
  selectApp,
  App => App.isExternal,
);

export const selectIsApplicationError = createSelector(
  selectApp,
  App => !!App.error,
);

export const selectApplicationError = createSelector(
  selectApp,
  App => App.error,
);

export const selectMenuType = createSelector(
  selectCustomization,
  customization => customization.menuType,
);

export * from './notifications/selectors';
