import api, { privateAPI } from '~/api/base';
import { CreateProfileRequestBody, ProfileSettingsValue } from '../schemas/profile';

const profileApi = {
  retrieve: () => privateAPI.get('/profile/'),

  createProfile: async (profileData: CreateProfileRequestBody) => privateAPI.post(
    '/profile/create/',
    profileData,
  ),

  changeProfile: async (profileData) => privateAPI.patch(
    '/profile/',
    profileData,
  ),

  getProfileMetaInfo: () => privateAPI.get<ProfileSettingsValue>('/profile/meta/'),

  setProfileMetaInfo: (newValue: ProfileSettingsValue) => privateAPI.patch<ProfileSettingsValue>('/profile/meta/', newValue),

  resetPassword: (data) => api.post(
    '/password-reset/',
    data,
  ),

  resetPasswordComplete: (data) => api.post(
    '/password-reset-complete/',
    data,
  ),

  resetPasswordVerify: (data) => api.post(
    '/password-reset-verify/',
    data,
  ),
};

export default profileApi;
