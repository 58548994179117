import { Portfolio } from '@models/portfolio';

import * as builder from '@store/builder';
import PortfolioTypes from '@store/portfolio/types';
import { BySymbolPortfolioResponse } from '@api/schemas/broker';

export const portfolioCreationStarted = () => ({
  type: PortfolioTypes.SET_CREATION_STARTED,
  payload: {
    creationStage: 'started',
  },
});

export const fetchPortfolio = builder.buildRequestAction(PortfolioTypes.FETCH_PORTFOLIO);
export const fetchPortfolioStarted = builder.buildStartedAction(PortfolioTypes.FETCH_PORTFOLIO_STARTED);
export const fetchPortfolioSucceeded = builder.buildSucceededAction<Portfolio>(PortfolioTypes.FETCH_PORTFOLIO_SUCCEEDED, 'portfolioInfo');
export const fetchPortfolioInfoSucceeded
    = builder.buildSucceededAction<BySymbolPortfolioResponse[]>(PortfolioTypes.FETCH_PORTFOLIO_INFO_SUCCEEDED, 'portfolioDetailedInfo');
export const fetchPortfolioFailed = builder.buildFailedAction(PortfolioTypes.FETCH_PORTFOLIO_FAILED);
