import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { ACHStateType, ACHWidget } from '~/widgets/ACH';
import { ManualACHForm } from '~/features/ACH';
import { ConnectError } from '~/features/ACH/ConnectExternal/types';

import { achSelector } from '../api';
import { ConnectComponentProps, CommonProps } from '../types';
import { FallbackDescription } from './FallbackDescription';
import * as Styled from './Common.styled';

export const PlaidACHInput: React.FC<
CommonProps & {
  ConnectComponent: React.ComponentType<ConnectComponentProps>;
}> = (props) => {
  const achRD = useSelector(achSelector);
  const [showManualFallback, setShowManualFallback] = useState(false);

  const onError = useCallback((e: ConnectError) => {
    props.onExternalError(e);
    setShowManualFallback(true);
  }, []);

  useEffect(() => {
    if (props.onManualChanged) {
      props.onManualChanged(showManualFallback);
    }
  }, [showManualFallback, props.onManualChanged]);

  return useMemo(() => {
    return F.pipe(
      achRD,
      RD.map(
        ach =>
          F.pipe(
            ach,
            () =>
              props.lockReason
                ? <ACHWidget reason={props.lockReason} type={ACHStateType.LOCKED} achRelationship={ach} />
                : <ACHWidget type={ACHStateType.PLAIN} achRelationship={ach} removable={props.removable} />,
          )
      ),
      RD.fold(
        () => <></>,
        () => <Styled.Loader />,
        () => showManualFallback ? (
          <>
            <FallbackDescription onClick={() => {
              setShowManualFallback(false);
              props.onExternalExit();
            }} />
            {ManualACHForm}
          </>
        )
          : <props.ConnectComponent onError={onError} />,
        F.identity,
      )
    );
  }, [props.lockReason, achRD, props.ConnectComponent, onError, showManualFallback]);
};
