import { TransfersState } from '@store/transfers/transfers.types';
import { getPendingTransfersSelector } from './pendging';

const transfersSelector = (state: any): TransfersState => {
  return state.Transfers;
};

const pendingSelector = getPendingTransfersSelector(transfersSelector);

export const transfersSelectors = {
  pending: pendingSelector
};
