import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { ACHRelationship } from '@models/transfers';
import { achSelector } from '~/entities/ach';
import { Button } from '~/ui/kit/atoms/Button';

import { DeleteACHConfirmationDialog } from './DeleteConfirmationDialog';
import * as Styled from './PlainAccount.styled';

type Props = ACHRelationship & {
  isRemovable: boolean;
  onRemove?: VoidFunction;
  className?: string;
  isExternal: boolean;
};

export const PlainAccount: FC<Props> = (props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const activeACHAccount = useSelector(achSelector);

  const isConnectedAccount = useMemo(() =>
    F.pipe(
      activeACHAccount,
      RD.map(({ account_number }) => account_number === props.account_number),
      RD.getOrElse(() => false)
    ),
  [activeACHAccount, props.account_number]);

  const closeConfirmation = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const openConfirmation = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  return (
    <Styled.Container className={props.className}>
      <DeleteACHConfirmationDialog visible={showConfirmation} onCancel={closeConfirmation} onAccept={props.onRemove} />
      <Styled.Info
        label={`${props.nickname || props.display_account_type || props.account_type}`}
        accountNumber={props.account_number}
        accountType={`${props.isExternal ? 'External' : ''} Account`}
        balance={props.balance}
        isConnected={isConnectedAccount}
      />
      {props.isRemovable && <Button type="ghost" onClick={openConfirmation}><Styled.Icon/></Button>}
    </Styled.Container>
  );
};
