import TopicCategoriesTypes from '@store/topic_categories/types';
import { FetchableState } from '@store/common/schemas';
import { TopicCategoriesAction } from '@store/topic_categories/schemas';
import { TopicCategories } from '@models/topic-categories';
import { isPayloadSafetyType } from '@store/util';

export interface TopicCategoriesState extends FetchableState {
  categories: TopicCategories;
}

const initialState: TopicCategoriesState = {
  isLoading: false,
  error: '',
  categories: null,
};

export default function topicCategoriesReducer (
  state: TopicCategoriesState = initialState,
  { type, payload }: TopicCategoriesAction,
): TopicCategoriesState {
  if (isPayloadSafetyType(type, TopicCategoriesTypes)) {
    return { ...state, ...payload };
  }
  return state;
}
