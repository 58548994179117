import React, { useContext } from 'react';

import { ThemeContext } from '~/contexts/app';

import * as S from './WithdrawDescription.styled';

export const useWithdrawDescription = (daysLimit = 5) => {
  const showEmoji = useContext(ThemeContext).isEmojiEnabled;

  return (
    <>
      {showEmoji && <S.ConfirmationEmoji>🙋‍♂️ </S.ConfirmationEmoji>}
       Please note: it can take up to {daysLimit} business days for the money to be deposited into your account
    </>
  );
};
