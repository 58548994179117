import { AnyAction } from 'redux';

import BrokerAccountTypes from '@store/broker_account/types';
import { FetchableState } from '@store/common/schemas';
import { isPayloadSafetyType } from '@store/util';

import { BrokerAccount } from '@models/broker';

export interface BrokerAccountState extends FetchableState {
  accountInfo: BrokerAccount | null;
}

const initialState: BrokerAccountState = {
  isLoading: false,
  error: '',
  accountInfo: null,
};

export default function brokerAccountReducer (
  state: BrokerAccountState = initialState,
  { type, payload }: AnyAction,
): BrokerAccountState {
  if (isPayloadSafetyType(type, BrokerAccountTypes)) {
    return { ...state, ...payload };
  }
  return state;
}
