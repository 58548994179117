import styled, { css } from 'styled-components';

import * as inputStyles from '~/ui/kit/molecules/Input/Input.styled';
import { List } from '~/ui/kit/organisms/List';
import { ListItem } from '~/ui/kit/organisms/ListItem';

import { PlainAccount } from '../PlainAccount';
import { ClickWrapper } from '~/ui/kit/organisms/ListItem/ListItem.organism.styled';

export const Container = styled(List)`
  &.ant-list-split .ant-list-item {
    &:last-child {
      border-block-end: ${inputStyles.INPUT_BORDER_WIDTH}px solid;
    }
  }
`;

export const ItemContainer = styled(ListItem)<{$selected?: boolean}>`
  min-height: 71px;
  position: relative;

  ${ClickWrapper} {
    padding: 0;
  }

  &.ant-list-item {
    ${inputStyles.border};
    ${inputStyles.borderedStyles};
    ${inputStyles.borderColor}
    
    margin-bottom: 8px;
    
    ${({ $selected }) => $selected && css`
      &, &:hover {
        border-color: var(--eko-primary-color) !important;
      }
    `}
    
    &:first-of-type {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Item = styled(PlainAccount)``;
