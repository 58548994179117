import styled from 'styled-components';

import { Input } from '~/ui/kit/molecules/Input';
import { baseFont } from '~/ui/kit/constants/fonts';

export const Container = styled(Input.Select).attrs({ fullWidth: true, listHeight: 250 })`
  .ant-select-dropdown {
    ${baseFont};

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      font-weight: normal;
    }
  }
`;
