import { createSelector } from 'reselect';

import { selectId } from '@store/common/selectors';
import { TopicsState } from '@store/topics/index';

import { Id } from '@models/common';
import { Topic, Topics } from '@models/topics';

const topicsSelector = (state): TopicsState => state.Topics2;

export const selectIsTopicsLoading = createSelector(
  topicsSelector,
  (Topics: TopicsState): boolean => Topics.isLoading,
);

export const selectTopics = createSelector(
  topicsSelector,
  (Topics: TopicsState): Topics => Topics.topics,
);

export const selectTopic = createSelector(
  [topicsSelector, selectId],
  (Topics: TopicsState, id: Id): Topic | null => (Topics.topics || []).find(topic => topic.id === id),
);

export const getTopicsSelectorByIds = (ids: number[]) => createSelector(topicsSelector, (topics) => {
  const topicsByIds = (topics.topics || []).reduce((acc, topic) => ({ ...acc, [topic.id]: topic }), {} as Record<number, Topic>);

  return ids.map(id => (topicsByIds[id])).filter(Boolean);
});
