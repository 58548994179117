import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';

type CallbackArgs = any[];

export const getResponseData = (response: AxiosResponse) => response.data;

const getErrorObjectStringValue = (val: any) => {
  if (typeof val === 'object') {
    if (typeof val.message === 'string') {
      return val.message;
    }

    const values = Object.values(val);

    for (let i = 0; i < values.length; i++) {
      if (typeof values[i] === 'string') {
        return values[i];
      }

      if (Array.isArray(values[i]) && typeof values[i][0] === 'string') {
        return values[i][0];
      }

      if (typeof values[i] === 'object') {
        const innerValue = getErrorObjectStringValue(values[i]);
        if (typeof innerValue === 'string') {
          return innerValue;
        }
      }
    }
  }
};

export const getResponseErrorMessage = (
  response: AxiosResponse | AxiosError,
  defaultErrorMessage = 'Processing error, please try again',
) => {
  let msg = defaultErrorMessage;

  if (response) {
    if ('data' in response) {
      const data = getResponseData(response);
      if (Array.isArray(data)) {
        msg = data[0] || msg;
      }
      else if (data?.non_field_errors) {
        if (Array.isArray(data?.non_field_errors)) {
          msg = data?.non_field_errors[0] || msg;
        }
        if (typeof data?.non_field_errors === 'string') {
          msg = data?.non_field_errors;
        }
      }
    }
    else {
      if (response.response && response.response.data) {
        const errorData = response.response.data;
        if (typeof errorData === 'object') {
          msg = getErrorObjectStringValue(response.response.data);
        }
      }
      msg = msg || response.message;
    }
  }
  return (msg || defaultErrorMessage).replace(/_/g, ' ');
};

export const response = async<T> (response: AxiosPromise): Promise<T> => response
  .then((res: AxiosResponse | AxiosError) => {
    if ((res as AxiosError).isAxiosError) {
      throw res;
    }
    return getResponseData(res as AxiosResponse);
  });

export function responseCallback<T> (func: (...args: CallbackArgs) => AxiosPromise): (...args: CallbackArgs) => Promise<T> {
  return (...args: CallbackArgs) => response<T>(func(...args));
}
