import { createSelector } from 'reselect';
import { HighlightedCompanyInfo, WeeklyCompanyPerformance } from '../../highlighted.company.types';

export const HighlightedCompanySelector = (state: any): {
  Info: HighlightedCompanyInfo,
  Performance: WeeklyCompanyPerformance
} => state.HighlightedCompany;

export const selectHighlightedCompanyInfo = createSelector(
  HighlightedCompanySelector,
  state => state.Info
);

export const selectHighlightedCompanyWeeklyPerformance = createSelector(
  HighlightedCompanySelector,
  state => state.Performance
);
