import { takeLeading } from 'redux-saga/effects';

import { FETCH_ONBOARDING_INVESTMENT_PROFILE_REQUIREMENTS } from '@store/onboarding/types';

import { prepareOnboardingInvestmentProfile } from '@services/onboarding';

export function* onboardingSaga () {
  yield takeLeading(
    FETCH_ONBOARDING_INVESTMENT_PROFILE_REQUIREMENTS,
    prepareOnboardingInvestmentProfile,
  );
}
