import { call, put } from 'redux-saga/effects';

import { Impact } from '@models/impact';

import * as actions from '@store/projection/actions';

import projectionApi from '@api/endpoints/projection';
import { responseCallback } from '@api/helpers';

export function* loadProjectionImpact () {
  try {
    yield put(actions.fetchImpactProjectionStarted());
    const impact: Impact = yield call(
      responseCallback<Impact>(projectionApi.impact.fetch),
    );
    yield put(actions.fetchImpactProjectionSucceeded(impact));
  }
  catch (e) {
    yield put(actions.fetchImpactProjectionFailed(e));
    throw e;
  }
}
