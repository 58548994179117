import { AnyAction } from 'redux';

import PortfolioTypes, { PortfolioCreationStage } from '@store/portfolio/types';
import { FetchableState } from '@store/common/schemas';
import { isPayloadSafetyType } from '@store/util';

import { Portfolio } from '@models/portfolio';
import { BySymbolPortfolioResponse } from '@api/schemas/broker';

export interface PortfolioState extends FetchableState {
  portfolioInfo: Portfolio;
  creationStage: PortfolioCreationStage | null;
  portfolioDetailedInfo: BySymbolPortfolioResponse[]
}

const initialState: PortfolioState = {
  isLoading: false,
  error: '',
  portfolioInfo: null,
  portfolioDetailedInfo: [],
  creationStage: null,
};

export default function portfolioReducer (
  state: PortfolioState = initialState,
  { type, payload }: AnyAction,
): PortfolioState {
  if (isPayloadSafetyType(type, PortfolioTypes)) {
    return { ...state, ...payload };
  }
  return state;
}
