import { put, takeLatest, spawn, call } from 'redux-saga/effects';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import * as builder from '@store/builder';
import { getIndividualPerformanceGraphData } from '~/services/portfolio';
import { getEndDate, getWeekStartDate } from '@utils/graphOptions';
import { mapApiError } from '@models/ApiError';

import { WeeklyCompanyPerformance } from '../../highlighted.company.types';
import { SET as SET_INFO_ACTION, setAction as setInfoAction } from './info.store';

const moduleAction = builder.getModuleAction('HIGHLIGHTED_COMPANY_PERFORMANCE');

const initialState: WeeklyCompanyPerformance = RD.initial;

export const SET = moduleAction('SET');
export const FETCH = moduleAction('FETCH');

const setAction = (payload: WeeklyCompanyPerformance) => ({
  type: SET,
  payload,
});

const fetchAction = (ticker: string) => ({
  type: FETCH,
  payload: ticker,
});

export const Performance = (state = initialState, action) => {
  switch (action.type) {
    case SET: return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

function* onCompanyStateChange (action: ReturnType<typeof setInfoAction>) {
  if (RD.isSuccess(action.payload)) {
    yield put(fetchAction(action.payload.value.ticker));
  }
  else {
    yield pipe(
      action.payload,
      RD.fold(
        () => put(setAction(RD.initial)),
        () => put(setAction(RD.pending)),
        (e) => put(setAction(RD.failure(e))),
        () => null,
      ),
    );
  }
}

function* fetchCompanyPerformance (action: ReturnType<typeof fetchAction>) {
  yield put(setAction(RD.pending));

  try {
    const performance = yield call(getIndividualPerformanceGraphData(action.payload, getWeekStartDate(), getEndDate()));

    yield put(
      setAction(
        RD.success(
          ((performance.bars[performance.bars.length - 1].c - performance.bars[0].c) / performance.bars[0].c) * 100
        )
      )
    );
  }
  catch (e) {
    yield put(setAction(RD.failure(mapApiError(e))));
  }
}

function* watchFetchPerformance () {
  yield takeLatest(FETCH, fetchCompanyPerformance);
}

function* watchCompany () {
  yield takeLatest(SET_INFO_ACTION, onCompanyStateChange);
}

export function* watchHighlightedCompanyPerformanceSaga () {
  yield spawn(watchCompany);
  yield spawn(watchFetchPerformance);
}
