import { AnyAction } from 'redux';

import Types from './types';

import { isPayloadSafetyType } from '@store/util';

import { Profile } from '@models/profile';

export interface ProfileState {
  profileInfo: Profile | null;
  isLoading: boolean;
  error: string;
}

const initialState: ProfileState = {
  profileInfo: null,
  isLoading: false,
  error: '',
};

export default function profileReducer (
  state: ProfileState = initialState,
  { type, payload }: { type: string, payload: AnyAction },
): ProfileState {
  if (type === Types.CLEAR_PROFILE) {
    return { ...state, profileInfo: null };
  }

  if (isPayloadSafetyType(type, Types)) {
    return { ...state, ...payload };
  }

  return state;
}
