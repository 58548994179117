import * as RD from '@devexperts/remote-data-ts';
import { Action } from 'redux';
import merge from 'lodash/merge';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { mapApiError } from '@models/ApiError';
import { editAlpacaAccountInfo, getBrokerageAccountData } from '~/services/alpaca';
import * as builder from '@store/builder';

import {
  BrokerageAccount as TBrokerageAccount,
  BrokerageAccountRD,
  BrokerageAccountDTO
} from '../../../brokerageAccount.entity.types';
import { mapDtoToDomain, mapDomainToDtoHelper } from '../../../helpers';
import { pipe } from 'fp-ts/function';

const initialState: BrokerageAccountRD = RD.initial;

const moduleAction = builder.getModuleAction('BrokerageAccount');

const SET = moduleAction('SET');

const FETCH = moduleAction('FETCH');

const UPDATE = moduleAction('UPDATE');

export const setAction = (payload: BrokerageAccountRD) => ({
  type: SET,
  payload,
});

export const BrokerageAccount = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export function* loadBrokerageAccount () {
  yield put(setAction(RD.pending));

  try {
    const res: BrokerageAccountDTO = yield call(getBrokerageAccountData());
    const mapped = mapDtoToDomain(res);
    yield put(setAction(RD.success(mapped)));
  }
  catch (e) {
    yield put(setAction(RD.failure(e)));
  }
}

export const loadAction = builder.buildRequestAction(FETCH);

type UpdateAction = Action<typeof UPDATE> & {
  payload: {
    data: Partial<TBrokerageAccount>;
    onError?: (error: string) => void;
    onSuccess?: (data: TBrokerageAccount) => void;
  }
}

export const updateAction = (
  data: Partial<TBrokerageAccount>,
  { onError, onSuccess }: { onError?: (e: string) => void, onSuccess?: (data: TBrokerageAccount) => void }
): UpdateAction => ({
  type: UPDATE,
  payload: {
    data,
    onError,
    onSuccess,
  }
});

export function* updateBrokerageAccount (action: UpdateAction) {
  const { BrokerageAccount: oldValue } = yield select();
  yield put(setAction(RD.pending));
  const { payload: { data, onError, onSuccess } } = action;

  try {
    const result = yield call(editAlpacaAccountInfo(mapDomainToDtoHelper(data)));
    if (onSuccess) {
      onSuccess(mapDtoToDomain(result));
    }
    yield put(loadAction());
  }
  catch (e) {
    onError && onError(mapApiError(e).message);
    const mergedValue = pipe(
      (oldValue as BrokerageAccountRD),
      RD.map((oldValue) => merge({}, oldValue, action.payload.data))
    );
    yield put(setAction(mergedValue));
  }
}

export function* watchLoadBrokerageSaga () {
  yield takeLatest(FETCH, loadBrokerageAccount);
  yield takeLatest(UPDATE, updateBrokerageAccount);
}
