import * as RD from '@devexperts/remote-data-ts';
import { call, put, takeLatest } from 'redux-saga/effects';

import brokerAPI from '@api/endpoints/broker';
import { response } from '@api/helpers';
import { mapApiError } from '@models/ApiError';
import { PendingStatusInfo } from '@models/transfers';

import * as builder from '../../builder';
import { PendingTransfersInfoState } from './pending-transfers.types';

const action = builder.getModuleAction('PENDING_TRANSFERS');

const SET = action('SET');

const FETCH = action('FETCH');

const initialState: PendingTransfersInfoState = RD.initial;

export const PendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const loadPendingTransfersInfoAction = builder.buildRequestAction(FETCH);

const setAction = (payload: PendingTransfersInfoState) => ({
  type: SET,
  payload
});

function* loadSaga () {
  yield put(setAction(RD.pending));

  try {
    const transfers: PendingStatusInfo[] = yield call(response, brokerAPI.getPendingTransfer());
    yield put(setAction(RD.success(transfers)));
  }
  catch (error) {
    yield put(setAction(RD.failure(error)));
  }
}

export function* watchPendingTransfersSaga () {
  yield takeLatest(FETCH, loadSaga);
}
