import { AxiosPromise } from 'axios';

import { privateAPI } from '~/api/base';

import { Impact } from '@models/impact';

const impactApi = {
  fetch: (): AxiosPromise<Impact> => privateAPI.get('/impact/'),
};

export default impactApi;
