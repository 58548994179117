import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import * as F from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as RD from '@devexperts/remote-data-ts';

import { ACHRelationship } from '@models/transfers';

import { editACHSelector, achSelector, achAccountsSelector } from '../api/selectors';

export const useActiveACHRelationship = () => {
  const editACH = useSelector(editACHSelector);
  const achRD = useSelector(achSelector);
  const accountsPlainRD = useSelector(achAccountsSelector);
  const accountsPlain = useMemo(() => F.pipe(accountsPlainRD, RD.getOrElse((): ACHRelationship[] => [])), [accountsPlainRD]);

  return useMemo(() => F.pipe(
    editACH,
    O.alt(() => RD.toOption(achRD)),
    O.map((ach) => ({
      ...ach,
      balance: ach.balance || accountsPlain.find(plainAccount => plainAccount.account_number === ach.account_number)?.balance
    }))
  ), [editACH, achRD, accountsPlain]);
};

export const useActiveAch = () => {
  const activeACHRelationship = useActiveACHRelationship();

  return useMemo(() => F.pipe(
    activeACHRelationship,
    O.map((ach) => ({
      destinationAddress: ach.account_number,
      destinationType: ach.nickname || ach.display_account_type,
      balance: ach.balance
    }))
  ), [activeACHRelationship]);
};

export const useDestination = () => {
  const activeACH = useActiveAch();

  return useMemo(() => F.pipe(
    activeACH,
    O.getOrElse(() => ({
      destinationAddress: '',
      destinationType: '',
    }))
  ), [activeACH]);
};
