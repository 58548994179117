import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';

import { useOnResize } from '~/ui/utils/effects/useOnResize.hook';

/** Based on /src/ui/utils/media/media.helper.ts */
export enum DeviceSize {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

type AppContextValue = {
  deviceSize: DeviceSize
}

const AppMediaContext = React.createContext<AppContextValue>({
  deviceSize: DeviceSize.xxs,
});

const deviceSizeOrder = [
  DeviceSize.xxs,
  DeviceSize.xs,
  DeviceSize.sm,
  DeviceSize.md,
  DeviceSize.lg,
  DeviceSize.xl,
  DeviceSize.xxl,
];

const moreOrEqualCheck = (sizeToCompareWith: DeviceSize) => (targetSize: DeviceSize) => {
  const targetIndex = deviceSizeOrder.indexOf(targetSize);
  const compareWithIndex = deviceSizeOrder.indexOf(sizeToCompareWith);

  return targetIndex >= compareWithIndex;
};

export const checkDeviceSize = {
  moreOrEqual: {
    xxs: moreOrEqualCheck(DeviceSize.xxs),
    xs: moreOrEqualCheck(DeviceSize.xs),
    sm: moreOrEqualCheck(DeviceSize.sm),
    md: moreOrEqualCheck(DeviceSize.md),
    lg: moreOrEqualCheck(DeviceSize.lg),
    xl: moreOrEqualCheck(DeviceSize.xl),
    xxl: moreOrEqualCheck(DeviceSize.xxl),
  }
};

export const AppMediaContextProvider: React.FC<PropsWithChildren> = (props) => {
  const [deviceSize, setDeviceSize] = useState<DeviceSize>(DeviceSize.xxs);

  useOnResize((width) => {
    /** Based on /src/ui/utils/media/media.helper.ts */
    if (width < 400) {
      setDeviceSize(DeviceSize.xxs);
      return;
    }
    if (width < 600) {
      setDeviceSize(DeviceSize.xs);
      return;
    }
    if (width < 768) {
      setDeviceSize(DeviceSize.sm);
      return;
    }
    if (width < 992) {
      setDeviceSize(DeviceSize.md);
      return;
    }
    if (width < 1200) {
      setDeviceSize(DeviceSize.lg);
      return;
    }
    if (width < 1600) {
      setDeviceSize(DeviceSize.xl);
      return;
    }
    setDeviceSize(DeviceSize.xxl);
    return DeviceSize.xxl;
  });

  const appContextValue: AppContextValue = useMemo(() => ({ deviceSize }), [deviceSize]);

  return <AppMediaContext.Provider value={appContextValue}>{props.children}</AppMediaContext.Provider>;
};

export const useAppMediaContext = () => {
  return useContext(AppMediaContext);
};
