import { AxiosError } from 'axios';
import { getResponseErrorMessage } from '@api/helpers';

export type ApiError = {
  code: number;
  message: string;
};

export const mapApiError = (errorData: AxiosError, defaultErrorMessage?: string): ApiError => {
  const status = Number(errorData.response?.status || errorData.code);
  const code = Number.isNaN(status) ? 0 : status;

  return {
    code,
    message: getResponseErrorMessage(errorData, defaultErrorMessage),
  };
};
