import { createSelector } from 'reselect';

import { PendingTransfersInfoState } from './pending-transfers.types';

export const getPendingTransfersSelector
    = <TransfersSelector extends (state: any) => {pending: PendingTransfersInfoState}>(transfersSelector: TransfersSelector) =>
      createSelector(
        transfersSelector,
        ({ pending }) => pending
      );
