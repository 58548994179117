import { useCallback, useEffect, useState } from 'react';

import { ScheduleItem } from '@pages/fund_withdraw/types';
import * as transfers from '@services/transfers';

type ScheduleResponse = {
  count: number;
  next?: string;
  previous?: string;
  results: Array<ScheduleItem>
}

export const useRecurringDeposits = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateToggle, setUpdateToggle] = useState(false);
  const [data, setData] = useState<ScheduleResponse>();

  const update = useCallback(() => {
    setUpdateToggle(prev => !prev);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    transfers
      .getRecurringDeposits()
      .then(({ data }) => data)
      .then(data => ({
        ...data,
        count: Number(data.count),
        results: data.results.map(scheduleItem => ({
          id: scheduleItem.id,
          type: scheduleItem.type,
          amount: Number(scheduleItem.amount),
          date: scheduleItem.next_payment_date ? new Date(scheduleItem.next_payment_date) : null,
          pausedAt: scheduleItem.paused_at ? new Date(scheduleItem.paused_at) : null,
          period: scheduleItem.period,
          status: scheduleItem.status,
          hasPendingDeposit: Boolean(scheduleItem.has_pending_deposit),
        }))
      }))
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [updateToggle]);

  return {
    update,
    isLoading,
    data
  };
};
