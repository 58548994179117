enum Type {
  SuccessfulDeposit = 'SuccessfulDeposit',
  SuccessfulWithdrawal = 'SuccessfulWithdrawal',
  AccountClosure = 'AccountClosure',
  VerifyEmail = 'VerifyEmail',
  Info = 'Info',
}

type Config = { closable: boolean };

type Content = Record<string, any>;
type TextContent = { text: string };

interface Payload<C1 extends Config, C2 extends Content> {
  config: C1;
  content: C2;
}

type BasicPayload = Payload<Config, Content>;

interface Notification {
  type: Type;
  payload: Payload<Config, Content>;
}

type Notifications = Notification[];

export {
  Notification,
  Notifications,
  Type as NotificationType,
  Payload as NotificationPayload,
  BasicPayload as NotificationBasicPayload,
  Config as NotificationConfig,
  Content as NotificationContent,
  TextContent as NotificationTextContent,
};
