export enum PortfolioCreationStage {
  'started',
}

export enum PortfolioTypes {
  FETCH_PORTFOLIO = 'FETCH_PORTFOLIO',
  FETCH_PORTFOLIO_STARTED = 'FETCH_PORTFOLIO_STARTED',
  FETCH_PORTFOLIO_SUCCEEDED = 'FETCH_PORTFOLIO_SUCCEEDED',
  FETCH_PORTFOLIO_FAILED = 'FETCH_PORTFOLIO_FAILED',
  FETCH_PORTFOLIO_INFO_SUCCEEDED = 'FETCH_PORTFOLIO_INFO_SUCCEEDED',

  SET_CREATION_STARTED = 'SET_CREATION_STARTED',
}

export default PortfolioTypes;
