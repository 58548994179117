export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_STARTED = 'FETCH_PROFILE_STARTED';
export const FETCH_PROFILE_SUCCEEDED = 'FETCH_PROFILE_SUCCEEDED';
export const FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED';

export const CLEAR_PROFILE = 'CLEAR_PROFILE';

enum Types {
  FETCH_PROFILE = 'FETCH_PROFILE',
  FETCH_PROFILE_STARTED = 'FETCH_PROFILE_STARTED',
  FETCH_PROFILE_SUCCEEDED = 'FETCH_PROFILE_SUCCEEDED',
  FETCH_PROFILE_FAILED = 'FETCH_PROFILE_FAILED',
  CLEAR_PROFILE = 'CLEAR_PROFILE',
}

export default Types;
