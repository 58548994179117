import styled from 'styled-components';

import { Button } from '~/ui/kit/atoms/Button';
import { IconArrowNext } from '~/ui/kit/atoms/IconArrowNext';
import { fontSize } from '~/ui/kit/constants/fonts';
import { gaps, getEkoBorderRadius } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

export const Container = styled.div.attrs({ $fs: 'xs' })`
  color: var(--eko-third-color);
  background: var(--eko-primary-color-light-6);
  ${fontSize};
  padding: ${gaps.s}px ${gaps.m}px;
  margin-bottom: ${gaps.s}px;
  
  border-radius: ${getEkoBorderRadius({ $br: 'm' })};
  
  ${media.md`
    padding: ${gaps.m}px ${gaps.xl}px;
    margin-bottom: ${gaps.m}px;
  `}
`;

export const Text = styled.div`
    margin-bottom: ${gaps.xs}px;
`;

export const BackButton = styled(Button).attrs({
  size: 'small',
  type: 'primary',
  ghost: true,
  fs: 'xs'
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 !important;
  border: none !important;
`;

export const Arrow = styled(IconArrowNext)`
  transform: rotateZ(180deg);
  width: 16px;
  margin-right: ${gaps.xxs / 2}px;
`;
