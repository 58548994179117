import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { AppContext } from '~/contexts/app';
import { selectIsAuthenticated } from '@store/authentication/selectors';
import { dispatchCompanyDefaults } from '@utils/application';
import { selectOrganization } from '@store/organization/selectors';
import { CompanySettingsContext, useSettings } from '~/services/company';
import { useCustomization } from '~/domain/effects/application';

const UnrestrictedRoute = ({ component: Component, ...rest }) => {
  const organization = useSelector(selectOrganization);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const companySettings = useSettings(organization);
  useCustomization(organization);
  const appDispatch = useContext(AppContext);

  useEffect(() => {
    dispatchCompanyDefaults(appDispatch, companySettings);
  }, [isAuthenticated, companySettings]);

  return (
    <Route
      {...rest}
      render={(props) => isAuthenticated
        ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
        : (
          <CompanySettingsContext.Provider value={companySettings}>
            <Component {...props} />
          </CompanySettingsContext.Provider>
        )
      }
    />
  );
};

export default connect()(UnrestrictedRoute);
