import React, { lazy, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-phone-input-2/lib/style.css';
import 'swiper/swiper-bundle.min.css';
import 'animate.css';

import './css/main.css';
import './assets/variables.css';
import './assets/custom.css';
import './assets/App.css';
import './assets/index.css';

import { AppContext, SettingsContext, ThemeContext } from '~/contexts/app';
import Loader from '~/ui/components/Loader/Loader';
import Routes from '~/routes';
import * as analytics from '@utils/analytics';
import { appReducer, appInitialState } from '@store/application/reducers';
import { loadProfileSettingAction, loadUserSettingAction } from '@store/settings';
import { useAuthentication } from '~/domain/effects/auth';
import { isDev } from '@constants/application';
import { useTecton } from '~/packages/tecton';

import './ui/antd.overrides.css';

const Toolbar = lazy(() => import('@components/utils/toolbar'));

const App = () => {
  const appLoading = useAuthentication();

  useTecton();

  const [
    { title, visual, behaviour },
    appDispatch,
  ] = useReducer(appReducer, appInitialState);

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.ekoScrollOutsideToTop();
  }, [location.href]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfileSettingAction());
    dispatch(loadUserSettingAction());
  }, []);

  return (
    <main>
      {appLoading
        ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        )
        : (
          <>
            {visual.styles && (
              <style>{visual.styles}</style>
            )}

            <AppContext.Provider value={action => appDispatch(action)}>
              <SettingsContext.Provider value={{
                achSelectionType: behaviour.achSelectionType,
                passwordChangeAvailable: behaviour.passwordChangeAvailable,
              }}>
                <ThemeContext.Provider value={{
                  logo: visual.logo,
                  displayName: visual.displayName,
                  isEmojiEnabled: visual.isEmojiOn,
                }}>
                  <Routes />
                </ThemeContext.Provider>
              </SettingsContext.Provider>
            </AppContext.Provider>
          </>
        )
      }

      {isDev && (
        <React.Suspense fallback={<div>loading...</div>}>
          <Toolbar />
        </React.Suspense>
      )}
    </main>
  );
};

export default App;
