import { call, put } from 'redux-saga/effects';

import topicCategoriesApi from '@api/endpoints/topic-categories';
import { responseCallback } from '@api/helpers';

import { TopicCategories } from '@models/topic-categories';
import * as actions from '@store/topic_categories/actions';

export function* loadTopicCategories () {
  try {
    yield put(actions.fetchTopicCategoriesStarted());
    const categories: TopicCategories = yield call(
      responseCallback<TopicCategories>(topicCategoriesApi.fetch),
    );
    yield put(actions.fetchTopicCategoriesSucceeded(categories));
  }
  catch (e) {
    yield put(actions.fetchTopicCategoriesFailed(e));
    throw e;
  }
}
