import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as O from 'fp-ts/Option';

import { ConnectError, ConnectErrorType } from '~/features/ACH/ConnectExternal/types';

import { setEditACH } from '../../api/stores';
import { ACHInput } from '../../ACHInput';
import { ConnectComponentProps } from '../../types';

type Params = {
  lockReason?: string;
  ConnectComponent: React.ComponentType<ConnectComponentProps>;
  disableExternalAccounts?: boolean;
  onManualChanged?: (isManual: boolean) => void;
}

export const useACHApi = (params: Params) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const onExternalError = useCallback((e: ConnectError) => {
    if (e.type === ConnectErrorType.BANK_NOT_FOUND) {
      setError('');
    }
    else {
      setError(e.messages && e.messages[0]);
    }
  }, []);

  const onExternalExit = useCallback(() => {
    setError('');
    dispatch(setEditACH(O.none));
  }, []);

  const achInput = useMemo(() => (
    <ACHInput
      onExternalError={onExternalError}
      onExternalExit={onExternalExit}
      disableExternalAccounts={params.disableExternalAccounts}
      ConnectComponent={params.ConnectComponent}
      lockReason={params.lockReason}
      removable={!params.lockReason}
      onManualChanged={params.onManualChanged}
    />
  ), [
    params.ConnectComponent,
    params.lockReason,
    params.disableExternalAccounts,
    params.onManualChanged,
  ]);

  return {
    achInput,
    error,
  };
};
