import { AxiosPromise } from 'axios';

import { privateAPI } from '~/api/base';
import { UniverseToAddResponse } from '@api/schemas/portfolio';

import { Portfolio } from '@models/portfolio';

const portfolioAPI = {
  retrieve: (): AxiosPromise<Portfolio> => privateAPI.get('/portfolio/'),
  addCompany: (ticker: string): AxiosPromise<unknown> => privateAPI.post(`/add-company-to-portfolio/${ticker}/`),
  fetchPortfolioAvailableUniverse: (page = 1, query = ''): AxiosPromise<UniverseToAddResponse> =>
    privateAPI.get(`/universe/?page=${page}${query ? '&' + query : ''}`),
  fetchUniverseCompany: (ticker: string): AxiosPromise<unknown> => privateAPI.get(
    `/universe/${ticker}/`,
  ),
  fetchPortfolioPerformance: ({ params }): AxiosPromise<unknown> => privateAPI.post(
    '/portfolio/performance/chart/',
    { ...params },
  ),
};

export default portfolioAPI;
