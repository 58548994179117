import { AxiosPromise } from 'axios';

import api, { privateAPI } from '~/api/base';
import { Topics } from '@models/topics';

const topicsApi = {
  fetchPublicTopics: (organizationId: number): AxiosPromise<Topics> => api.get(`platform/topics/?organization=${organizationId}`),
  fetch: (): AxiosPromise<Topics> => privateAPI.get('/platform/topics/'),
};

export default topicsApi;
