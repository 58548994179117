import Types from './types';
import { Action } from './actions';

import { isPayloadSafetyType } from '@store/util';

import { Organization } from '@models/organization';

export interface OrganizationState {
  organizationInfo: Organization | null;
  isLoading: boolean;
  error: string;
  disclosure?: string | null
}

const initialState: OrganizationState = {
  organizationInfo: null,
  isLoading: false,
  error: '',
};

export default function organizationReducer (
  state: OrganizationState = initialState,
  { type, payload }: Action,
): OrganizationState {
  if (isPayloadSafetyType(type, Types)) {
    return { ...state, ...payload };
  }
  return state;
}
