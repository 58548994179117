import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import * as F from 'fp-ts/function';

import { ACH_SELECTION_TYPES } from '@constants/accounts';
import { ManualACHForm } from '~/features/ACH';
import ACHLoading from '@components/achrelationship/components/ach_loading';

import { selectACHSelectionType } from './api/selectors';
import { ManualACHInput, PlaidACHInput, IntegratedACHInput } from './ui';
import { ConnectComponentProps, CommonProps } from './types';

const map = (params: CommonProps & {
  ConnectComponent: React.ComponentType<ConnectComponentProps>;
}) => (selectionType: ACH_SELECTION_TYPES) => {
  switch (selectionType) {
    case ACH_SELECTION_TYPES.EXTERNAL_ACCOUNT:
      return (
        <IntegratedACHInput
          onManualChanged={params.onManualChanged}
          onExternalError={params.onExternalError}
          onExternalExit={params.onExternalExit}
          disableExternalAccounts={params.disableExternalAccounts}
          ConnectComponent={params.ConnectComponent}
          lockReason={params.lockReason}
          removable={params.removable}
        />
      );

    case ACH_SELECTION_TYPES.PLAID:
      return (
        <PlaidACHInput
          onManualChanged={params.onManualChanged}
          onExternalError={params.onExternalError}
          onExternalExit={params.onExternalExit}
          ConnectComponent={params.ConnectComponent}
          lockReason={params.lockReason}
          removable={params.removable}
        />
      );

    case ACH_SELECTION_TYPES.MANUAL:
      return (
        <ManualACHInput
          onManualChanged={params.onManualChanged}
          removable={params.removable}
          onExternalError={params.onExternalError}
          onExternalExit={params.onExternalExit}
        />
      );
  }
  return (
    <ManualACHInput
      removable={params.removable}
      onExternalError={params.onExternalError}
      onExternalExit={params.onExternalExit}
    />
  );
};

type ACHInputProps = CommonProps & {
  ConnectComponent: React.ComponentType<ConnectComponentProps>;
}

export const ACHInput: React.FC<ACHInputProps> = (props) => {
  const achSelectionType = useSelector(selectACHSelectionType);

  const mapper = useCallback(map(props), [props.ConnectComponent, props.lockReason]);

  const onFail = useCallback(() => ManualACHForm, []);

  return F.pipe(
    achSelectionType,
    RD.fold(
      () => <></>,
      () => {
        if (props.onManualChanged) {
          props.onManualChanged(false);
        }

        return <ACHLoading />;
      },
      onFail,
      mapper,
    ),
  );
};
