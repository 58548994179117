import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import ACHLoading from '@components/achrelationship/components/ach_loading';
import { ManualACHForm } from '~/features/ACH';
import { ConnectErrorType } from '~/features/ACH/ConnectExternal/types';
import { ACHStateType, ACHWidget } from '~/widgets/ACH';

import { achSelector } from '../api/selectors';
import { CommonProps } from '../types';

export const ManualACHInput: React.FC<CommonProps> = (props) => {
  const achRD = useSelector(achSelector);
  useEffect(() => {
    if (props.onManualChanged) {
      props.onManualChanged(true);
    }

    return () => {
      if (props.onManualChanged) {
        props.onManualChanged(false);
      }
    };
  }, []);

  return useMemo(() => F.pipe(
    achRD,
    RD.fold(
      () => <></>,
      () => <ACHLoading />,
      () => {
        props.onExternalError({ type: ConnectErrorType.UNKNOWN });
        return (
          <>{ManualACHForm}</>
        );
      },
      (ach) =>
        props.lockReason
          ? <ACHWidget reason={props.lockReason} type={ACHStateType.LOCKED} achRelationship={ach}/>
          : <ACHWidget type={ACHStateType.PLAIN} achRelationship={ach} removable={props.removable}/>,
    )
  ), [achRD]);
};
