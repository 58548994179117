import { call, put, takeLatest } from 'redux-saga/effects';
import * as RD from '@devexperts/remote-data-ts';

import { response } from '@api/helpers';

import { AlpacaAccountState } from './alpaca_account.types';
import * as builder from '../builder';
import brokerAPI from '../../api/endpoints/broker';

const initialState: AlpacaAccountState = RD.initial;

const action = builder.getModuleAction('ALPACA_ACCOUNT');

const SET = action('SET');

const FETCH = action('FETCH');

export const AlpacaAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const loadAlpacaAccountAction = builder.buildRequestAction(FETCH);

export const setAlpacaAccount = (payload: AlpacaAccountState) => ({
  type: SET,
  payload: payload
});

export function* loadAlpacaAccount () {
  yield put(setAlpacaAccount(RD.pending));
  try {
    const accounts = yield call(response, brokerAPI.fetchAlpacaAccount());
    yield put(setAlpacaAccount(accounts ? RD.success(accounts) : RD.success([])));
  }
  catch (e) {
    yield put(setAlpacaAccount(RD.failure(e)));
  }
}

export function* watchAlpacaAccountSaga () {
  yield takeLatest(FETCH, loadAlpacaAccount);
}
