import { spawn } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { Info, Performance, watchHighlightedCompanySaga, watchHighlightedCompanyPerformanceSaga } from './stores';

export * from './selectors';

export function* highlightedCompanySagas () {
  yield spawn(watchHighlightedCompanySaga);
  yield spawn(watchHighlightedCompanyPerformanceSaga);
}

export const HighlightedCompany = combineReducers({
  Info,
  Performance,
});
