import { AnyAction } from 'redux';

import { Impact } from '@models/impact';

import { FetchableState } from '@store/common/schemas';
import { ImpactProjectionFetchTypes } from '@store/projection/types';

export interface ImpactProjectionState extends FetchableState {
  projectionInfo: Impact | null;
}

const initialState: ImpactProjectionState = {
  projectionInfo: null,
  isLoading: false,
  error: '',
};

export default function impactReducer (
  state: ImpactProjectionState = initialState,
  { type, payload }: AnyAction,
): ImpactProjectionState {
  switch (type) {
    case ImpactProjectionFetchTypes.STARTED:
    case ImpactProjectionFetchTypes.SUCCEEDED:
    case ImpactProjectionFetchTypes.FAILED:
      return { ...state, ...payload };

    default:
      return state;
  }
}
