import * as types from '../types/types';

export const setFullPortfolioItem = company => ({
  type: types.SET_FULL_PORTFOLIO_ITEM,
  company,
});

export const setFullPortfolioItemLoading = (ticker, isLoading) => ({
  type: types.SET_FULL_PORTFOLIO_ITEM_LOADING,
  payload: { ticker, isLoading },
});

export const setPortfolioPopupLoading = isLoading => ({
  type: types.PORTFOLIO_POPUP_LOADING,
  isLoading,
});
