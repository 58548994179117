import { ACHRelationship } from '@models/transfers';
import { differenceInDays, parseISO } from 'date-fns';

export const SWITCH_DISABLED_BY_RECURRING = 'You cannot change bank account while recurring deposit is in progress.';

export const SWITCH_DISABLED_BY_PENDING_DEPOSIT = 'You cannot change bank account while pending deposit is in progress.';

export const SWITCH_DISABLED_BY_PENDING_WITHDRAWAL = 'You cannot change your account while there is pending a withdrawal in progress.';

const DAYS_TO_GET_TRUSTED = 60;

export const getWithdrawLockedReasonByTrustedPeriod = (account: ACHRelationship): string | null => {
  const createdAt = parseISO(account.created_at);
  const daysSinceCreation = differenceInDays(new Date(), createdAt);
  const daysLeftToBeTrusted = DAYS_TO_GET_TRUSTED - daysSinceCreation;

  if (daysLeftToBeTrusted < 1) {
    return null;
  }

  return `You are not able to switch your withdrawal account within 60 days of the initial connection. `
        + `You will be able to change it in ${daysLeftToBeTrusted} days`;
};
