import authAPI from '@api/endpoints/auth';
import profileApi from '@api/endpoints/profile';
import { getResponseData, response } from '@api/helpers';

import { TOKEN_LOCAL_STORAGE_NAME } from '@constants/constants';
import { AxiosResponse } from 'axios';

import {
  removeAccessToken,
  removeRefreshToken,
  saveAccessToken,
  saveRefreshToken,
} from '~/authentication/auth';

import { AuthData, Credentials, SignupData, TokensResponse } from '@models/auth';

import {
  clearEmailVerificationClosed,
  clearVisitsSinceLogin,
} from '@services/application';

import { retrieve } from '@utils/persistence';

export class InvalidAuthentication extends Error {}

export const isCredentialsValid = (creds: Credentials) => !!creds.token;

export const getCredentials = (): Credentials => ({
  token: retrieve(TOKEN_LOCAL_STORAGE_NAME),
});

export const login = async (authData: AuthData) => {
  try {
    const { access, refresh } = await response<TokensResponse>(authAPI.getTokens(authData));
    saveAccessToken(access);
    saveRefreshToken(refresh);
  }
  catch (e) {
    throw new InvalidAuthentication(
      e.response?.data?.detail || 'Unable to login'
    );
  }
};

export const GENERAL_AUTH_ERROR = (
  'Seems like out developers are currently working on making Eko better.'
  + 'Could you try again a little bit later?'
);

export class SignupError extends Error {
  errors: string[];

  constructor (errors: string[]) {
    super();
    this.errors = errors;
  }
}

export const signup = async (signupData: SignupData) => {
  try {
    await response(authAPI.register(signupData));
    const { access, refresh } = await response<TokensResponse>(authAPI.getTokens({
      email: signupData.email,
      password: signupData.password,
    }));
    saveAccessToken(access);
    saveRefreshToken(refresh);
  } catch (e) {
    const errs = [];

    if (e.response) {
      const res = e.response as AxiosResponse;

      if (res.status === 400) {
        const data = getResponseData(res);

        if (data?.email?.[0] === 'This field must be unique') {
          errs.push('This email already exist');
        }

        if (data?.password?.[0]) {
          errs.push(...data.password);
        }
      }
    }

    if (!errs.length) {
      errs.push(GENERAL_AUTH_ERROR);
    }

    throw new SignupError(errs);
  }
};

export const logout = async () => {
  removeAccessToken();
  removeRefreshToken();
  clearVisitsSinceLogin();
  clearEmailVerificationClosed();
};
