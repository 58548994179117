import { createSelector } from 'reselect';
import * as RD from '@devexperts/remote-data-ts';
import { ACH_SELECTION_TYPES } from '@constants/accounts';
import { organizationSelector, selectIsOrganizationLoading } from '@store/organization/selectors';

export const selectACHSelectionType = createSelector(
  organizationSelector,
  selectIsOrganizationLoading,
  organizationSelector,
  (organization, loading, error): RD.RemoteData<Error, ACH_SELECTION_TYPES> => {
    if (loading) {
      return RD.pending;
    }
    if (error.error) {
      return RD.failure(new Error(error.error));
    }
    if (organization) {
      return RD.success(organization.organizationInfo.ach_selection);
      // return RD.success(3);
    }
    return RD.initial;
  });
