import { AxiosPromise } from 'axios';

import { privateAPI } from '~/api/base';

const waitlistApi = {
  register: (data): AxiosPromise<unknown> => privateAPI.post(
    '/waitlist/country/',
    data,
  ),
};

export default waitlistApi;
