import * as builder from '@store/builder';
import { put, select, spawn, takeLatest } from 'redux-saga/effects';
import * as RD from '@devexperts/remote-data-ts';

import { PortfolioTypes } from '@store/portfolio/types';
import { portfolioSelector, selectIsPortfolioLoading } from '@store/portfolio/selectors';
import { profileSelector, selectIsProfileLoading } from '@store/profile/selectors';
import {
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_STARTED,
  FETCH_PROFILE_SUCCEEDED
} from '@store/profile/types';

import { getHighlightedCompanyFromPortfolio } from '../../helpers';
import { HighlightedCompanyInfo } from '../../highlighted.company.types';

const moduleAction = builder.getModuleAction('HIGHLIGHTED_COMPANY_INFO');

const initialState: HighlightedCompanyInfo = RD.initial;

export const SET = moduleAction('SET');

export const setAction = (payload: HighlightedCompanyInfo) => ({
  type: SET,
  payload,
});

export const Info = (state = initialState, action) => {
  switch (action.type) {
    case SET: return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

function* watchPendings () {
  yield put(setAction(RD.pending));
}

function* watchFails () {
  const state = yield select();

  const Profile = profileSelector(state);
  const Portfolio2 = portfolioSelector(state);

  if (Profile.error || Portfolio2.error) {
    yield put(setAction(RD.failure({ message: Profile.error || Portfolio2.error, code: 403 })));
  }
}

function* watchSuccess () {
  const state = yield select();

  const Profile = profileSelector(state);
  const Portfolio2 = portfolioSelector(state);

  if (selectIsPortfolioLoading(state) || selectIsProfileLoading(state)) {
    return yield put(setAction(RD.pending));
  }

  if (Profile.error || Portfolio2.error) {
    return yield put(setAction(RD.failure({ message: Profile.error || Portfolio2.error, code: 403 })));
  }

  const res = getHighlightedCompanyFromPortfolio(Portfolio2.portfolioInfo, Profile.profileInfo);

  if (res) {
    return yield put(setAction(RD.success(res)));
  }

  return yield put(setAction(RD.failure({ message: 'No highlighted company in portfolio', code: 404 })));
}

function* profileSagas () {
  yield takeLatest(FETCH_PROFILE_STARTED, watchPendings);
  yield takeLatest(FETCH_PROFILE_FAILED, watchFails);
  yield takeLatest(FETCH_PROFILE_SUCCEEDED, watchSuccess);
}

function* portfolioSagas () {
  yield takeLatest(PortfolioTypes.FETCH_PORTFOLIO_STARTED, watchPendings);
  yield takeLatest(PortfolioTypes.FETCH_PORTFOLIO_FAILED, watchFails);
  yield takeLatest(PortfolioTypes.FETCH_PORTFOLIO_SUCCEEDED, watchSuccess);
}

export function* watchHighlightedCompanySaga () {
  yield spawn(profileSagas);
  yield spawn(portfolioSagas);
}
