import * as RD from '@devexperts/remote-data-ts';
import { ProfileSettingsValue } from '@api/schemas/profile';

import { ProfileSettings } from './settings.store.types';

export const profileSettingsSelector = (state: any): ProfileSettings => state.Settings;

export const useSettingsEq = RD.getEq<Error, ProfileSettingsValue>({
  equals: (e1, e2) => e1 === e2
}, {
  equals: (a, b) => a.portfolioOnboardingPassed === b.portfolioOnboardingPassed
});
