import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import appReducer from './application';
import organizationReducer from './organization';
import profileReducer from './profile';
import { init as initSaga } from '~/data/middlewares/saga';
import middlewares from '~/data/middlewares';
import application from '~/reducers/application';
import DeprecatedPortfolio from '~/reducers/PortfolioReducer';
import OnboardingEmployee from '~/reducers/OnboardingEmployeeReducer';
import AppTypes from '@store/application/types';
import portfolioReducer from '@store/portfolio';
import topicsReducer from '@store/topics';
import topicCategoriesReducer from '@store/topic_categories';
import impactReducer from '@store/impact';
import projectionReducer from '@store/projection';
import brokerAccountReducer from '@store/broker_account';
import authenticationReducer from '@store/authentication';
import onboardingReducer from '@store/onboarding';
import { TransfersReducer } from '@store/transfers';
import onboardingAlpacaReducer from '@store/alpaca_onboarding';
import { AlpacaAccountReducer } from '@store/alpaca_account';
import { achReducer } from '~/entities/ach';
import { BrokerageAccount } from '~/entities/brokerageAccount/api/stores';
import { HighlightedCompany } from '~/entities/company/highlighted';
import { profileSettingsReducer, userSettingsReducer } from '@store/settings';

const reducers = combineReducers({
  Profile: profileReducer,
  Organization: organizationReducer,
  Application: appReducer,
  Portfolio2: portfolioReducer,
  Topics2: topicsReducer,
  TopicCategories: topicCategoriesReducer,
  Impact: impactReducer,
  Projection: projectionReducer,
  BrokerAccount: brokerAccountReducer,
  Authentication: authenticationReducer,
  Onboarding: onboardingReducer,
  AlpacaOnboarding: onboardingAlpacaReducer,
  application,
  DeprecatedPortfolio,
  OnboardingEmployee,
  ACH: achReducer,
  BrokerageAccount,
  HighlightedCompany,
  AlpacaAccount: AlpacaAccountReducer,
  Transfers: TransfersReducer,
  Settings: profileSettingsReducer,
  UserSettings: userSettingsReducer,
});

let preloadedState = {};
let composeEnhancers = compose;

if (typeof window !== 'undefined') {
  preloadedState = window.__PRELOADED_STATE__ || preloadedState;
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
}

const rootReducer = (state, action) => {
  if (action.type === AppTypes.RESET) {
    state = preloadedState;
  }
  return reducers(state, action);
};

const store = createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(
    applyMiddleware(...middlewares),
  ),
);

initSaga();

export default store;
