import { combineReducers } from 'redux';
import { spawn } from 'redux-saga/effects';

import {
  watchACHSaga,
  ACHReducer,
  loadACHAction,
  setPlaidACHAction,
  setACHAction,
  removeACHAction,
} from './ACHReducer';

import {
  watchACHAccountsSaga,
  ACHAccounts,
  loadACHAccounts,
  loadACHAccountsAction
} from './ACHAccounts';

import { EditACHReducer, setEditACH } from './EditACHReducer';

function* sagas () {
  yield spawn(watchACHSaga);
  yield spawn(watchACHAccountsSaga);
}

const achReducer = combineReducers({
  profile: ACHReducer,
  accounts: ACHAccounts,
  edit: EditACHReducer,
});

export {
  loadACHAction,
  setPlaidACHAction,
  setACHAction,
  removeACHAction,
  achReducer,
  sagas,
  setEditACH,
  loadACHAccounts,
  loadACHAccountsAction
};
