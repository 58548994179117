import { call, put } from 'redux-saga/effects';

import * as actions from '@store/organization/actions';

import organizationAPI from '@api/endpoints/organization';
import { responseCallback } from '@api/helpers';

import { Organization } from '@models/organization';

export function* loadOrganization () {
  try {
    yield put(actions.fetchOrganizationStarted());
    const organization: Organization = yield call(
      responseCallback<Organization>(organizationAPI.retrieve),
    );
    yield put(actions.fetchOrganizationSucceeded(organization));
    return organization;
  } catch (e) {
    yield put(actions.fetchOrganizationFailed(e));
    throw e;
  }
}
