export const LANGS = ["en"];
export const DEFAULT_LANG = LANGS[0];
export const LANG_REGEX = LANGS.slice(1).join("|");
export const DEFAULT_LANG_ROUTE = "";
// export const API_DOMAIN = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'http://67.205.140.1';
export const TOKEN_LOCAL_STORAGE_NAME = "eko-token";
export const REFRESH_TOKEN_LOCAL_STORAGE_NAME = "eko-refresh-token";
export const API_DOMAIN = process.env.API_DOMAIN;

export const PAGE_TITLE = 'Eko Investments';
export const DISPLAY_NAME = 'Eko';

export const ImpactProjectionCardType = {
  CLEAN_POWER: "CLEAN_POWER",
  CO2: "CO2",
};

export const logoURL =
  "https://eko-app.s3.us-east-1.amazonaws.com/static/Logo.svg";
