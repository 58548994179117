import { createSelector } from 'reselect';

import { profileSelector } from '@store/profile/selectors';
import { ProfileState } from '@store/profile';
import { FetchableState } from '@store/common/schemas';

export const selectIsAuthenticated = createSelector(
  profileSelector,
  (Profile: ProfileState): boolean => !!Profile.profileInfo,
);

const loginSelector = state => state.Authentication.login;

export const selectIsLoginLoading = createSelector(
  loginSelector,
  (Login: FetchableState) => Login.isLoading,
);

export const selectLoginError = createSelector(
  loginSelector,
  (Login: FetchableState) => Login.error,
);
