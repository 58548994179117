import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';
import { format } from 'date-fns';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import { put, call, takeLatest, select } from 'redux-saga/effects';

import profileApi from '@api/endpoints/profile';
import { response } from '@api/helpers';
import { ProfileSettingsDTO, ProfileSettingsValue } from '@api/schemas/profile';
import * as builder from '@store/builder';

import { profileSettingsSelector } from './profileSettings.selectors';
import { ProfileSettings } from './settings.store.types';

const DATE_FORMAT = 'yyyy-MM-dd';

const action = builder.getModuleAction('PROFILE_SETTINGS');

const FETCH = action('FETCH');
const SET = action('SET');
const MODIFY = action('MODIFY');

const initialState: ProfileSettings = RD.initial;

export const profileSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }
    default:
      return state;
  }
};

const setProfileInfo = (payload: ProfileSettings) => ({
  type: SET,
  payload: payload
});

function* loadProfileSettings () {
  yield put(setProfileInfo(RD.pending));

  try {
    const profileSettings: ProfileSettingsDTO = yield call(response, profileApi.getProfileMetaInfo());
    const result: ProfileSettingsValue = {
      portfolioOnboardingPassed: profileSettings.portfolioOnboardingPassed,
      dontShowInitialDisclaimer: profileSettings.dontShowInitialDisclaimer,
      hideInitialDisclaimerDate: profileSettings.hideInitialDisclaimerDate ? new Date(profileSettings.hideInitialDisclaimerDate) : undefined,
    };

    if (profileSettings.hideInitialDisclaimerDate) {
      const date = parse(profileSettings.hideInitialDisclaimerDate, DATE_FORMAT, new Date());
      if (isValid(date)) {
        result.hideInitialDisclaimerDate = date;
      }
    }

    yield put(setProfileInfo(profileSettings ? RD.success(result) : RD.success({})));
  }
  catch (e) {
    yield put(setProfileInfo(RD.failure(e)));
  }
}
export function* watchLoadProfileSettings () {
  yield takeLatest(FETCH, loadProfileSettings);
}
export const loadProfileSettingAction = builder.buildRequestAction(FETCH);

function* modifyProfileSettings (action) {
  try {
    const state = yield select();
    const settingsState = profileSettingsSelector(state);
    const requestData = { ...action.payload };
    if (action.payload.hideInitialDisclaimerDate) {
      requestData.hideInitialDisclaimerDate = format(action.payload.hideInitialDisclaimerDate, DATE_FORMAT);
    }

    const apiResponse: ProfileSettingsValue = yield call(response, profileApi.setProfileMetaInfo(requestData));

    const result = pipe(
      settingsState,
      RD.map(data => ({ ...data, ...apiResponse })),
      RD.alt(() => RD.success({ portfolioOnboardingPassed: true, dontShowInitialDisclaimer: false } as ProfileSettingsValue))
    );
    yield put(setProfileInfo(result));
  }
  catch (_) {
    const state = yield select();
    const settingsState = profileSettingsSelector(state);
    const result = pipe(
      settingsState,
      RD.map(data => ({ ...data, portfolioOnboardingPassed: true })),
      RD.alt(() => RD.success({ portfolioOnboardingPassed: true }))
    );
    yield put(setProfileInfo(result));
  }
}

export function* watchModifyProfileSettings () {
  yield takeLatest(MODIFY, modifyProfileSettings);
}

export const modifyProfileSettingAction = (payload: ProfileSettingsValue) => ({
  type: MODIFY,
  payload
});
