import * as RD from '@devexperts/remote-data-ts';

import { ACHRelationship } from '@models/transfers';
import { mapApiError } from '@models/ApiError';
import { call, put, takeLatest } from 'redux-saga/effects';
import { response } from '@api/helpers';
import brokerApi from '@api/endpoints/broker';
import { ACHRelationshipResponse } from '@api/schemas/broker';
import { isEmpty, isEqual } from 'lodash';
import { DEFAULT_ACH_RELATIONSHIP } from '@components/achrelationship/constants';
import * as builder from '@store/builder';
import { Action } from 'redux';
import { mapACHRelationshipResponse } from '@domain/dtoMappers/ACH/relashionship';
import { validateACH } from '@services/transfers';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import plaidApi from '@api/endpoints/plaid';

import { ACHValue } from '../types';

const initialState: ACHValue = RD.initial;

const SET = 'SET_ACH_STATE';

const FETCH = 'FETCH_ACH';

const REMOVE = 'REMOVE_ACH';

const UPDATE = 'UPDATE_ACH';

const UPDATE_PLAID = 'UPDATE_ACH_PLAID';

export const ACHReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET: {
      return action.payload;
    }

    default:
      return state;
  }
};

export const setACH = (payload: ACHValue) => ({
  type: SET,
  payload: payload
});

export function* loadACH () {
  yield put(setACH(RD.pending));
  try {
    const res: ACHRelationshipResponse = yield call(response, brokerApi.fetchACHRelationship());
    if (!res) {
      yield put(setACH(RD.initial));
    }
    else {
      const ach = mapACHRelationshipResponse(res);

      const empty = isEqual(ach, DEFAULT_ACH_RELATIONSHIP);
      const valid = isEmpty(validateACH(ach));

      if (valid && !empty) {
        yield put(setACH(RD.success(ach)));
      }
      else {
        yield put(setACH(RD.failure(new Error('There is no available ACH relationship'))));
      }
    }
  }
  catch (e) {
    yield put(setACH(RD.failure(e)));
    // yield put(setACH(RD.success(O.none)));
  }
}

export function* createRelationship (newRelationship: ACHRelationship) {
  const payload = {
    account_owner_name: newRelationship.account_owner_name,
    bank_account_type: newRelationship.account_type,
    bank_account_number: newRelationship.account_number,
    bank_routing_number: newRelationship.routing_number,
  };

  put(setACH(RD.pending));

  yield response(brokerApi.setACHRelationship(payload));
  yield call(loadACH);
}

export function* removeAch () {
  yield put(setACH(RD.pending));
  try {
    yield call(response, brokerApi.deleteACHRelationship());
  }
  catch (_) {}

  yield call(loadACH);
}

type UpdateAction = Action<typeof UPDATE> & {
  payload: {
    ach: ACHRelationship;
    onSuccess?: () => void;
    onError?: (e: string) => void;
  }
}

type UpdatePlaidAction = Action<typeof UPDATE_PLAID> & {
  payload: {
    publicToken: string,
    meta: PlaidLinkOnSuccessMetadata
    onError?: (e: string) => void;
    fallbackValue?: RD.RemoteData<Error, ACHRelationship>
  }
}

export const setPlaidACHAction = (
  publicToken: string,
  meta: PlaidLinkOnSuccessMetadata,
  onSuccess?: () => void,
  onError?: (e: string) => void,
  fallbackValue?: RD.RemoteData<Error, ACHRelationship>,
):UpdatePlaidAction => ({
  type: UPDATE_PLAID,
  payload: {
    publicToken,
    meta,
    onError,
    fallbackValue
  }
});

export const setACHAction = (
  ach: ACHRelationship,
  onSuccess?: VoidFunction,
  onError?: (e: string) => void,
): UpdateAction => ({
  type: UPDATE,
  payload: {
    ach,
    onSuccess,
    onError,
  }
});

export function* setACHByPlaidToken (action: UpdatePlaidAction) {
  yield put(setACH(RD.pending));

  const { payload: { publicToken, meta, onError } } = action;

  try {
    const newAch = {
      public_token: publicToken,
      account_id: meta.accounts[0].id,
      account_name: meta.accounts[0].name,
      account_mask: meta.accounts[0].mask,
      institution_id: meta.institution.institution_id,
    };

    yield plaidApi.createAccount(newAch);

    yield call(response, brokerApi.setPlaidPlaid(newAch));
  }
  catch (e) {
    onError && onError(mapApiError(e).message);
  }
  yield call(loadACH);
}

export function* updateACH (action: UpdateAction) {
  yield put(setACH(RD.pending));
  const { payload: { ach: newRelationship, onSuccess, onError } } = action;

  try {
    yield call(createRelationship, newRelationship);
    onSuccess && onSuccess();
  }
  catch (e) {
    onError && onError(mapApiError(e).message);
    yield call(loadACH);
  }
}

export function* watchACHSaga () {
  yield takeLatest(FETCH, loadACH);
  yield takeLatest(UPDATE, updateACH);
  yield takeLatest(REMOVE, removeAch);
  yield takeLatest(UPDATE_PLAID, setACHByPlaidToken);
}

export const loadACHAction = builder.buildRequestAction(FETCH);

export const removeACHAction = builder.buildRequestAction(REMOVE);
