import React from 'react';
import { InputProps, InputType } from '~/ui/kit/molecules/Input';

import * as Styled from './ACHManualForm.styled';
import { mapFormItem } from '~/ui/kit/organisms/Form/Form.organism.helpers';
import { achValidators } from '@components/achrelationship/validators';

const manualInputs: InputProps[] = [
  {
    id: 'name',
    type: InputType.STRING,
    label: 'Account owner',
    required: true,
  },
  {
    id: 'accountType',
    label: 'Account type',
    type: InputType.RADIO,
    options: [
      {
        value: 'CHECKING',
        label: 'Checking',
      },
      {
        value: 'SAVINGS',
        label: 'Savings',
      }
    ],
    required: true,
  },
  {
    id: 'accountNumber',
    type: InputType.STRING,
    label: 'Bank account number',
    required: true,
  },
  {
    id: 'accountRouting',
    type: InputType.STRING,
    label: 'Bank routing number',
    validationRules: [{
      validator: (_, value = '') => {
        const validationError = achValidators.routing_number(value);
        if (!validationError) {
          return Promise.resolve();
        }
        return Promise.reject(Error(validationError));
      }
    }]
  },
];

export const ManualACHForm = <Styled.Container>{manualInputs.map(mapFormItem)}</Styled.Container>;
