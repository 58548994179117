import styled from 'styled-components';

import { IconTrash } from '~/ui/kit/atoms/IconTrash';
import { AccountInfo } from '~/ui/kit/molecules/AccountInfo';

export const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Info = styled(AccountInfo)`
`;

export const Icon = styled(IconTrash)`
  height: 20px;
  color: var(--eko-third-color);
`;
