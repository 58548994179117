import { ImpactFetchTypes } from '@store/impact/types';

import * as builder from '../builder';
import { Impact } from '@models/impact';

export const fetchImpact = builder.buildRequestAction(ImpactFetchTypes.REQUEST);
export const fetchImpactStarted = builder.buildStartedAction(ImpactFetchTypes.STARTED);
export const fetchImpactSucceeded = builder.buildSucceededAction<Impact>(
  ImpactFetchTypes.SUCCEEDED,
  'impactInfo',
);
export const fetchImpactFailed = builder.buildFailedAction(ImpactFetchTypes.FAILED);
