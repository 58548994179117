import { PendingStatusInfoDTO } from '@api/schemas/broker';
import { PendingStatusInfo } from '@models/transfers';

export const pendingTransfersDtoMapper = (dto: PendingStatusInfoDTO): PendingStatusInfo => ({
  status: dto.status,
  id: dto.id,
  amount: dto.amount,
  direction: dto.direction,
  createdAt: dto.created_at,
  isSellAll: dto.sell_all,
});
