import alpacaOnboardingSaga from '@store/alpaca_onboarding/sagas';
import { AxiosError } from 'axios';
import { all, call, put, spawn, takeLatest } from 'redux-saga/effects';

import * as appActions from '@store/application/actions';
import { portfolioSaga } from '@store/portfolio/sagas';
import { profileSaga } from '@store/profile/sagas';
import { impactSaga } from '@store/impact/sagas';
import { projectionSaga } from '@store/projection/sagas';
import { organizationSaga } from '@store/organization/sagas';
import AppTypes from '@store/application/types';

import { HTTP_STATUSES } from '@api/constants';
import * as authUtils from '~/authentication/auth';

import { loadProfile } from '@services/profile';
import { loadOrganization } from '@services/organization';
import { loadPortfolio } from '@services/portfolio';
import { loadTopics } from '@services/topics';
import { loadTopicCategories } from '@services/topic-categories';
import { topicSaga } from '@store/topics/sagas';
import { topicCategorySaga } from '@store/topic_categories/sagas';
import { onboardingSaga } from '@store/onboarding/sagas';
import { brokerAccountSaga } from '@store/broker_account/sagas';
import { sagas as achSagas } from '~/entities/ach';
import { sagas as alpacaAccountSagas } from '@store/alpaca_account';
import { brokerageAccountSagas } from '~/entities/brokerageAccount/api/stores';
import { watchTransfersSaga } from '@store/transfers';
import { loadProfileSettingAction, profileSettingsSaga, userSettingsSaga, loadUserSettingAction } from '@store/settings';
import { highlightedCompanySagas } from '~/entities/company/highlighted';

const isUserWithoutProfile = (status: number) => status === HTTP_STATUSES.NOT_FOUND;

function* loadError (e: AxiosError) {
  if (isUserWithoutProfile(e.response?.status)) {
    yield put(appActions.setIsExternal(true));
  }
}

function* initQueryAuth (
  accessToken: string,
  refreshToken: string,
  successCallback: () => void,
) {
  authUtils.saveAccessToken(accessToken);
  authUtils.saveRefreshToken(refreshToken);
  yield call(loadProfile);
  successCallback();
}

function* loadApp ({ payload }: appActions.LoadAppAction) {
  try {
    yield put(appActions.loadingAppStarted());

    if (payload?.tokens) {
      yield call(() => initQueryAuth(
        payload.tokens.accessToken,
        payload.tokens.refreshToken,
        payload.successCallback,
      ));
    }
    else {
      yield call(loadProfile);
    }

    yield all([
      call(loadOrganization),
      call(loadPortfolio),
    ]);
    yield all([
      spawn(loadTopics),
      spawn(loadTopicCategories),
    ]);

    yield put(loadProfileSettingAction());
    yield put(loadUserSettingAction());

    yield put(appActions.loadingAppSucceeded());
  }
  catch (e) {
    yield call(loadError, e);
    yield put(appActions.loadingAppFailed(e));
  }
}

function* applicationSaga () {
  yield takeLatest(AppTypes.LOAD, loadApp);
  yield spawn(portfolioSaga);
  yield spawn(profileSaga);
  yield spawn(impactSaga);
  yield spawn(projectionSaga);
  yield spawn(organizationSaga);
  yield spawn(topicSaga);
  yield spawn(topicCategorySaga);
  yield spawn(onboardingSaga);
  yield spawn(brokerAccountSaga);
  yield spawn(alpacaOnboardingSaga);
  yield spawn(achSagas);
  yield spawn(brokerageAccountSagas);
  yield spawn(alpacaAccountSagas);
  yield spawn(watchTransfersSaga);
  yield spawn(profileSettingsSaga);
  yield spawn(userSettingsSaga);
  yield spawn(highlightedCompanySagas);
}

export default applicationSaga;
