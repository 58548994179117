import { ConfirmationTypes } from '@store/alpaca_onboarding/types';
import { isPayloadSafetyType } from '@store/util';

export interface OnboardingAlpacaState {
  confirmation: {
    isLoading: boolean;
    completed: boolean;
    error: string;
  },
}

const initialData: OnboardingAlpacaState = {
  confirmation: {
    completed: false,
    isLoading: false,
    error: '',
  },
};

export default function onboardingAlpacaReducer (
  state = initialData,
  { type, payload },
): OnboardingAlpacaState {
  if (isPayloadSafetyType(type, ConfirmationTypes)) {
    const confirmation = { ...payload };

    if (type === ConfirmationTypes.SUCCEEDED) {
      confirmation.completed = true;
    }
    else {
      confirmation.completed = false;
    }

    return { ...state, confirmation: { ...confirmation } };
  }

  return state;
}
