import { select, spawn } from 'redux-saga/effects';

import { loadTopicsForOnboarding } from '@services/topics';

import { loadTopicCategories } from '@services/topic-categories';
import { selectOrganization } from '@store/organization/selectors';

export function* prepareOnboardingInvestmentProfile () {
  const org = (yield select(selectOrganization));
  const orgId = org ? org.id : 1;
  yield spawn(() => loadTopicsForOnboarding(orgId));
  yield spawn(loadTopicCategories);
}
