import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import * as O from 'fp-ts/Option';

import * as transfers from '@services/transfers';

import {
  achAccountsSelector,
  achSelector,
  editACHSelector,
  useIsActiveAccountExternalSelector
} from '../api/selectors';
import { loadACHAccountsAction } from '../api/stores';
import { ChangeAccountAssert } from '../ui/ChangeAccountAssert';
import { RetryAccountsLoad } from '../ui/RetryAccountsLoad';

type Params = {
  lockReason?: string;
  isManualFallback?: boolean;
  disabledExternalAccountsMessage?: React.ReactNode;
  disableExternalAccounts?: boolean;
}

export const useACHInputDescription = ({
  lockReason,
  isManualFallback = false,
  disabledExternalAccountsMessage = null,
  disableExternalAccounts = false,
}: Params) => {
  const dispatch = useDispatch();
  const achRD = useSelector(achSelector);
  const editACH = useSelector(editACHSelector);
  const accountsRD = useSelector(achAccountsSelector);
  const isActiveAccountExternal = useIsActiveAccountExternalSelector();

  const reloadAccounts = useCallback(() => {
    dispatch(loadACHAccountsAction());
  }, []);

  const accountsFailureDescription = useMemo(() => F.pipe(
    accountsRD,
    RD.fold(
      () => null,
      () => null,
      () => <RetryAccountsLoad onRetry={reloadAccounts}/>,
      () => null,
    )
  ), [accountsRD, reloadAccounts]);

  const assertMessage = useMemo(() => (
    <ChangeAccountAssert>
      {disableExternalAccounts && isActiveAccountExternal ? disabledExternalAccountsMessage : null}
    </ChangeAccountAssert>
  ), [
    disableExternalAccounts,
    isActiveAccountExternal,
    disabledExternalAccountsMessage,
  ]);

  const achInputDescription = useMemo(() => F.pipe(
    achRD,
    RD.toOption,
    ach => O.sequenceArray([ach, editACH]),
    O.map(([currentACH, editACH]) => {
      return transfers.isSameAccounts(currentACH, editACH);
    }),
    O.map((areAccountsEqual) => areAccountsEqual ? null : assertMessage),
    O.getOrElse(() => {
      if (lockReason) {
        return <>{lockReason}</>;
      }

      if (RD.isSuccess(achRD) && accountsFailureDescription !== null) {
        return <>{accountsFailureDescription}</>;
      }

      if (RD.isSuccess(achRD) && isManualFallback) {
        return assertMessage;
      }
    })
  ), [
    achRD,
    editACH,
    lockReason,
    isManualFallback,
    accountsFailureDescription,
    assertMessage,
  ]);

  return achInputDescription;
};
