import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as F from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { altOnError, sequenceT } from '@utils/RemoteData';

import { achAccountsSelector } from './achAccountsSelector';
import { achSelector } from './activeACH.selector';

export const useIsActiveAccountExternalSelector = () => {
  const achRD = useSelector(achSelector);
  const accountsPlain = useSelector(achAccountsSelector);

  const accounts = useMemo(() => F.pipe(
    accountsPlain,
    altOnError(() => RD.success([]))
  ), [accountsPlain]);

  const allowedAch = useMemo(() => F.pipe(
    sequenceT(achRD, accounts),
    RD.chain(([currentAch, integratedAccounts]) => {
      if (integratedAccounts[0] && currentAch.account_number !== integratedAccounts[0].account_number) {
        return RD.success(true);
      }

      return RD.success(false);
    })
  ), [achRD, accounts]);

  return F.pipe(
    allowedAch,
    RD.getOrElse(() => false),
  );
};
