import ReactDOM from 'react-dom';

import AppProvider from 'src/AppProvider';
import { isDev } from './constants/application';

ReactDOM.render(
  <AppProvider />,
  document.getElementById('app'),
);

if (isDev && module.hot) {
  module.hot.accept();
}
