import { createSelector } from 'reselect';

import { PortfolioState } from '@store/portfolio';

import { Portfolio, PortfolioCompany } from '@models/portfolio';
import { isPortfolioItemBond, isPortfolioItemCompany, isPortfolioItemETF } from '@services/portfolio';
import { PortfolioCreationStage } from '@store/portfolio/types';

export const portfolioSelector = (state): PortfolioState => state.Portfolio2;

export const selectIsPortfolioLoading = createSelector(
  portfolioSelector,
  (Portfolio: PortfolioState): boolean => Portfolio.isLoading,
);

export const selectPortfolio = createSelector(
  portfolioSelector,
  (Portfolio: PortfolioState): Portfolio => Portfolio.portfolioInfo,
);

export const selectPortfolioInfo = createSelector(
  portfolioSelector,
  (Portfolio: PortfolioState) => Portfolio.portfolioDetailedInfo,
);

export const selectAllInvestedAmount = createSelector(
  selectPortfolioInfo,
  (items) => (items || []).reduce((acc, cur) => {
    const value = Number(cur.market_value);
    return Number.isNaN(value) ? acc : acc + value;
  },
  0)
);

export const selectCurrentPortfolioItemsAmount = createSelector(
  selectPortfolioInfo,
  selectPortfolio,
  (orderPositions = [], portfolio = []) => {
    const additionsCount = portfolio.filter(
      portfolioItem => portfolioItem.pending_addition
    ).length;

    const removingCount = portfolio.filter(
      portfolioItem => portfolioItem.pending_deletion
    ).length;

    return orderPositions.length + additionsCount - removingCount;
  }
);

export const selectPortfolioCreationStage = createSelector(
  portfolioSelector,
  (Portfolio: PortfolioState): PortfolioCreationStage | null => Portfolio.creationStage,
);

export const selectPortfolioCompanies = createSelector(
  selectPortfolio,
  (portfolio: Portfolio): PortfolioCompany[] => (portfolio || []).map(portfolio => portfolio.company),
);

export const selectPortfolioCompaniesInfo = createSelector(
  selectPortfolio,
  (portfolio: Portfolio): number => (portfolio || []).filter(item => isPortfolioItemCompany(item.company)).length,
);

export const selectNonRemovingPortfolio = createSelector(
  selectPortfolio,
  (portfolio) => (portfolio || []).filter(portfolioItem => !portfolioItem.pending_deletion)
);

export const selectPortfolioCompaniesCount = createSelector(
  selectNonRemovingPortfolio,
  (portfolio: Portfolio): number => (portfolio || []).filter(item => isPortfolioItemCompany(item.company)).length,
);
export const selectPortfolioETFsCount = createSelector(
  selectNonRemovingPortfolio,
  (portfolio: Portfolio): number => (portfolio || []).filter(item => isPortfolioItemETF(item.company)).length,
);

export const selectPortfolioBondsCount = createSelector(
  selectNonRemovingPortfolio,
  (portfolio: Portfolio): number => (portfolio || []).filter(item => isPortfolioItemBond(item.company)).length,
);
