import { ACHRelationshipResponse } from '@api/schemas/broker';
import { ACHRelationship } from '@models/transfers';

export const mapACHRelationshipResponse = (ach: ACHRelationshipResponse): ACHRelationship => ({
  account_owner_name: ach.account_owner_name,
  account_number: ach.bank_account_number,
  account_type: ach.bank_account_type,
  routing_number: ach.bank_routing_number,
  nickname: ach.nickname,
  balance: undefined,
  display_account_type: ach.bank_account_type,
  created_at: ach.created_at,
});
