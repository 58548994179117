import { createSelector } from 'reselect';

import { ProfileState } from '@store/profile';
import * as local from '@store/profile/local';

import { Profile } from '@models/profile';

import * as services from '@services/profile';

import { retrieve } from '@utils/persistence';

export const profileSelector = (state: any): ProfileState => state.Profile;

export const selectProfile = createSelector(
  profileSelector,
  (Profile: ProfileState): Profile | null => Profile.profileInfo,
);

export const selectIsProfileLoading = createSelector(
  profileSelector,
  (Profile: ProfileState): boolean => Profile.isLoading,
);

export const selectIsProfileInitialLoading = createSelector(
  selectProfile,
  selectIsProfileLoading,
  (profile: Profile | null, loading: boolean): boolean => profile === null && loading,
);

export const selectIsProfileFunded = createSelector(
  selectProfile,
  selectIsProfileLoading,
  (profile: Profile | null): boolean => services.isProfileFunded(profile),
);

export const getHasAlpacaAccount = (): boolean =>
  retrieve(local.HAS_ALPACA_ACCOUNT_FIELD) === 'true';
export const getIsAccountClosure = (): boolean =>
  retrieve(local.ACCOUNT_CLOSURE_FIELD) === 'true';
