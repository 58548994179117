import { call, put } from 'redux-saga/effects';

import topicsApi from '@api/endpoints/topics';
import { responseCallback } from '@api/helpers';

import { Topic, Topics } from '@models/topics';
import { PortfolioCompany, PortfolioItem } from '@models/portfolio';
import * as actions from '@store/topics/actions';
import { getIssueTypeDisplay, isPortfolioItemBond, isPortfolioItemCompany } from '@services/portfolio';
import { Id } from '@models/common';
import { useSelector, useStore } from 'react-redux';
import { selectTopic } from '@store/topics/selectors';
import { useMemo } from 'react';

const mapTopic = (topic: Topic | undefined, portfolioItem: PortfolioItem) => {
  const company = portfolioItem.company;
  let label = topic?.name || 'Unknown';

  if (portfolioItem.major_topic === null) {
    if (portfolioItem.from_extra_bucket) {
      label = 'Stability Position';
    }
    else {
      label = 'Other';
    }
  }
  if (isPortfolioItemBond(portfolioItem.company)) {
    label = '';
  }

  if (company.issue_type && !isPortfolioItemCompany(company)) {
    const display = getIssueTypeDisplay(company);

    if (display) {
      label += label ? ` | ${display}` : display;
    }
  }

  return label;
};

export const mergePortfolioWithTopicLabel = <T extends PortfolioItem>(portfolio: T[]) => {
  const store = useStore();
  const state = store.getState();

  return useMemo(() => portfolio.map((item) => ({
    ...item,
    topicLabel: mapTopic(selectTopic(state, item.major_topic), item)
  })), [state.Topics2?.topics, portfolio]);
};

export const getPortfolioMajorTopicLabel = (portfolioItem: PortfolioItem) => {
  const topic = useSelector(
    (state) => selectTopic(state, portfolioItem.major_topic),
  );

  return mapTopic(topic, portfolioItem);
};

export const getTopicLabel = (topic: Topic | null, company: PortfolioCompany) => {
  let label = topic?.name || 'Other';

  if (company.issue_type && !isPortfolioItemCompany(company)) {
    const display = getIssueTypeDisplay(company);

    if (display) {
      label += label ? ` | ${display}` : display;
    }
  }

  return label;
};

export const getIsDetailsAvailable = (topic: Topic) => !!topic.detailed_description;

export function* loadTopics (organizationId: Id = null) {
  try {
    yield put(actions.fetchTopicsStarted());
    let topics: Topics;

    if (organizationId) {
      topics = yield call(
        responseCallback<Topics>(topicsApi.fetchPublicTopics),
        organizationId,
      );
    }
    else {
      topics = yield call(
        responseCallback<Topics>(topicsApi.fetch),
      );
    }

    yield put(actions.fetchTopicsSucceeded(topics));
  }
  catch (e) {
    yield put(actions.fetchTopicsFailed(e));
    throw e;
  }
}

export function* loadTopicsForOnboarding (organizationId: Id) {
  yield loadTopics(organizationId);
}
