import axios, { AxiosPromise } from 'axios';

import * as transfersMappers from '@domain/dtoMappers/transfers';
import { privateAPI } from '~/api/base';
import * as schemas from '@api/schemas/broker';

import { mapApiError } from '@models/ApiError';
import { BrokerAccount, ScheduleRequestBody, TransferRequestBody, WithdrawAllRequestBody } from '@models/broker';
import { PaginatedResult, PendingStatusInfoDTO, ScheduleDTO } from '@api/schemas/broker';
import { onInfoNotification } from '@domain/events/info';
import { BROKER_LOADING_ERROR } from '@domain/constants/error-messages';

const brokerApi = {
  fetchBrokerData: (): AxiosPromise<BrokerAccount> => privateAPI.get(
    '/platform/broker-data/',
  ),
  fetchBrokerAgeAccount: (): AxiosPromise<BrokerAccount> => privateAPI.get(
    '/broker/brokerage-account/',
  ),

  fetchPortfolio: (): AxiosPromise<unknown> => privateAPI.get(
    '/broker/portfolio/',
  ),
  fetchPortfolioBySymbol: (symbol: string): AxiosPromise<schemas.BySymbolPortfolioResponse> => privateAPI.get(
    `/broker/portfolio/${symbol}/`,
  ),
  fetchPortfolioHistory: ({ params }): AxiosPromise<unknown> => privateAPI.get(
    '/broker/portfolio/history/',
    { params },
  ),

  fetchAccountTradingInfo: (): AxiosPromise<schemas.TradingAccountResponse> => privateAPI.get(
    `/broker/trading-account/`,
  ),

  validateEmail: (email: string): AxiosPromise<schemas.ValidateEmailResponse> => privateAPI.post(
    '/broker/email/check/',
    { email },
  ),

  fetchIndividualPerformance: ({ symbol, params = {} }): AxiosPromise<unknown> => privateAPI.get(
    `/broker/stocks/${symbol}/bars`,
    { params },
  ).catch((e) => {
    onInfoNotification(BROKER_LOADING_ERROR);
    throw e;
  }),

  fetchAlpacaAccount: (): AxiosPromise<unknown> => privateAPI.get(
    '/broker/alpaca-account/me/',
  ),
  changeAlpacaAccount: (data): AxiosPromise<unknown> => privateAPI.patch(
    '/broker/alpaca-account/me/',
    data,
  ),
  closeAccount: (data): AxiosPromise<unknown> => privateAPI.post(
    '/broker/alpaca-account/close/',
    data,
  ),
  fetchDocumentsBy: (type): AxiosPromise<unknown> => privateAPI.get(
    `/broker/documents/?type=${type}`,
  ),
  submitDocuments: (data): AxiosPromise<unknown> => privateAPI.post(
    '/broker/alpaca-account/documents/',
    data,
  ),
  submitKYC: (data): AxiosPromise<unknown> => privateAPI.post(
    '/broker/alpaca-account/me/kyc/',
    data,
  ),
  downloadDocument: (docID): AxiosPromise<unknown> => privateAPI.get(
    `/broker/documents/${docID}/download/`,
  ),
  onboarding: (data): AxiosPromise<unknown> => privateAPI.post(
    '/broker/alpaca-account/',
    data,
  ),

  fetchACHRelationship: (): AxiosPromise<schemas.ACHRelationshipResponse> => privateAPI.get(
    '/broker/ach-relationship/my-current/',
  ),
  createACHRelationship: (data: schemas.ACHRelationshipBody): AxiosPromise<unknown> => privateAPI.post(
    '/broker/ach-relationship/',
    data,
  ),
  setACHRelationship: (data: schemas.ACHRelationshipBody): AxiosPromise<schemas.ACHRelationshipResponse> => privateAPI.put(
    '/broker/ach-relationship/my-current/',
    data,
  ),
  deleteACHRelationship: (): AxiosPromise<unknown> => privateAPI.delete(
    '/broker/ach-relationship/my-current/',
  ),

  transfer: (data: TransferRequestBody): AxiosPromise<unknown> => privateAPI.post(
    '/broker/transfers/',
    data,
  ),

  withdrawAll: (data: WithdrawAllRequestBody): AxiosPromise<unknown> => privateAPI.post(
    '/broker/transfers/', {
      sell_all: true,
      transfer_type: data.transfer_type,
      direction: 'OUTGOING',
    },
  ),

  getPendingTransfer: () => privateAPI.get<PendingStatusInfoDTO[]>(
    '/broker/transfers/pending/',
  ).then(data => {
    if (axios.isAxiosError(data)) {
      throw data.toJSON();
    }
    return {
      ...data,
      data: data.data.map(transfersMappers.pending)
    };
  }).catch(e => {
    throw mapApiError(e);
  }),

  getRecurringTransfers: (): AxiosPromise<PaginatedResult<ScheduleDTO>> => privateAPI.get(
    '/broker/payments/schedules/',
  ),

  createRecurringTransfer: (data: ScheduleRequestBody): AxiosPromise<unknown> => privateAPI.post(
    '/broker/payments/schedules/',
    data,
  ),

  editRecurringTransfer: (id: number, data: ScheduleRequestBody): AxiosPromise<unknown> => privateAPI.patch(
    `/broker/payments/schedules/${id}/`,
    data,
  ),

  resumeRecurringTransfer: (id: number, data: ScheduleRequestBody): AxiosPromise<unknown> => privateAPI.post(
    `/broker/payments/schedules/${id}/activate/`,
    data,
  ),

  removeRecurringDeposit: (data: { scheduleId: number }): AxiosPromise<unknown> => privateAPI.delete(
    `/broker/payments/schedules/${data.scheduleId}/`,
  ),

  connectPlaid: (data: { account_id: string }): AxiosPromise<unknown> => privateAPI.post(
    '/broker/ach-relationship/plaid/',
    data,
  ),

  setPlaidPlaid: <T extends { account_id: string }>(data: T): AxiosPromise<unknown> => privateAPI.put(
    '/broker/ach-relationship/plaid/',
    data,
  ),
};

export default brokerApi;
