import * as types from './types';

import { isPayloadSafetyType } from '@store/util';

import { Id } from '@models/common';
import { Reason } from '@models/onboarding';

export interface OnboardingState {
  legalName: string;
  email: string;
  investmentAmount: number;
  appetite: string;
  reason: Reason;
  risk: number,
  recommendedRisk: number;
  stepsValid: { name: string, isValid: boolean }[],
  topics: Id[],
}

const initialState: OnboardingState = {
  legalName: '',
  email: '',
  investmentAmount: 100,
  appetite: null,
  reason: null,
  risk: 0,
  recommendedRisk: 0,
  stepsValid: [],
  topics: [],
};

export default function onboardingReducer (
  state = initialState,
  { type, payload },
): OnboardingState {
  if (type === types.PERSISTENCE_ONBOARDING_TYPES.SET_STEPS_VALIDITY) {
    const steps = [...state.stepsValid];
    const index = steps.findIndex(step => step.name === payload.name);

    if (index === -1) {
      steps.push(payload);
    }
    else {
      steps[index].isValid = payload.isValid;
    }

    return {
      ...state,
      stepsValid: steps,
    };
  }

  if (isPayloadSafetyType(type, types.PERSISTENCE_ONBOARDING_TYPES)) {
    return { ...state, ...payload };
  }

  return { ...state };
}
