import TopicTypes from '@store/topics/types';
import { FetchableState } from '@store/common/schemas';
import { TopicsAction } from '@store/topics/schemas';
import { isPayloadSafetyType } from '@store/util';

import { Topics } from '@models/topics';

export interface TopicsState extends FetchableState {
  topics: Topics;
}

const initialState: TopicsState = {
  isLoading: false,
  error: '',
  topics: null,
};

export default function topicsReducer (
  state: TopicsState = initialState,
  { type, payload }: TopicsAction,
): TopicsState {
  if (isPayloadSafetyType(type, TopicTypes)) {
    return { ...state, ...payload };
  }
  return state;
}
