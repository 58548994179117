import React, { FC, useCallback } from 'react';

import { ListProps } from '../../ACH.feature.types';
import { isExternalAccountsFactory, useAccountsValue } from '../helpers';
import * as Styled from './AccountList.styled';

type Props = ListProps;

export const AccountList: FC<Props> = (props) => {
  const achAccounts = useAccountsValue();
  const isExternalAccount = useCallback(isExternalAccountsFactory(achAccounts), [achAccounts]);

  const onClick = useCallback((accountId: string) => () => {
    const account = props.accounts.find(account => account.account_number === accountId);
    if (account) {
      props.onSelect((account.account_number));
    }
  }, [props.onSelect, props.accounts]);

  return (
    <Styled.Container>
      {props.before}
      {props.accounts.map((account) => (
        <Styled.ItemContainer
          onClick={onClick(account.account_number)}
          key={account.account_number}
          $selected={account.account_number === props.activeAccount}
        >
          <Styled.Item {...account} isExternal={isExternalAccount(account)} isRemovable={false} />
        </Styled.ItemContainer>
      ))}
    </Styled.Container>
  );
};
