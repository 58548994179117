import Types from './types';
import { Action } from './actions';

import { isPayloadSafetyType } from '@store/util';

import { Customization, MenuType } from '@models/application';
import { Notifications } from '@models/application/notifications';

export interface AppState {
  isLoading: boolean;
  error: string;
  isExternal: boolean;
  customization: Customization;
  notifications: Notifications;
}

const initialState: AppState = {
  isLoading: true,
  error: '',
  isExternal: false,
  customization: {
    menuType: 'horizontal',
  },
  notifications: [],
};

export default function appReducer (state: AppState = initialState, { type, payload }: Action) {
  if (type === Types.SET_MENU) {
    return {
      ...state,
      customization: {
        ...state.customization,
        menuType: payload as MenuType,
      },
    };
  }

  if (isPayloadSafetyType(type, Types)) {
    return { ...state, ...payload };
  }

  return state;
}
