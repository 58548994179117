import { AxiosPromise } from 'axios';

import { privateAPI } from '~/api/base';
import {
  CreateAccountRequest,
  CreateAccountResponse,
  CreateLinkRequest,
  CreateLinkResponse
} from '@api/schemas/plaid';

const plaidApi = {
  createLinkToken: (data?: CreateLinkRequest): AxiosPromise<CreateLinkResponse> => privateAPI.post(
    '/plaid/link-token/',
    data,
  ),
  createAccount: (data: CreateAccountRequest): AxiosPromise<CreateAccountResponse> => privateAPI.post(
    '/plaid/accounts/',
    data,
  ),
};

export default plaidApi;
