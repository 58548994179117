import * as RD from '@devexperts/remote-data-ts';
import * as O from 'fp-ts/Option';

import { ACHRelationship } from '@models/transfers';

export type ACHValue = RD.RemoteData<Error, ACHRelationship>;

export type ACHAccountsValue = RD.RemoteData<Error, ACHRelationship[]>;

export type EditACHValue = O.Option<ACHRelationship>;

export enum MenuItem {
  Fund = 'Fund',
  Withdraw = 'Withdraw',
}
