import { AnyAction } from 'redux';

import { FetchableState } from '@store/common/schemas';
import { ImpactFetchTypes } from '@store/impact/types';
import { isPayloadSafetyType } from '@store/util';

import { Impact } from '@models/impact';

export interface ImpactState extends FetchableState {
  impactInfo: Impact | null;
}

const initialState: ImpactState = {
  impactInfo: null,
  isLoading: false,
  error: '',
};

export default function impactReducer (
  state: ImpactState = initialState,
  { type, payload }: AnyAction,
): ImpactState {
  if (isPayloadSafetyType(type, ImpactFetchTypes)) {
    return { ...state, ...payload };
  }
  return state;
}
